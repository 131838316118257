import { Icon } from '@iconify/react';
import ShopLogo from 'components/Logo/ShopLogo';
import Modal from 'components/Modal/Modal';
import ShowRating from 'pages/Dashboard/components/ShowRating';
import { appIcons } from 'config/appIcons';
import axiosClient from 'config/axiosClient';
import tokenHeader from 'config/tokenHeader';
import useCurrentShop from 'hooks/useCurrentShop';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { handleErrors } from 'store/actions/globalActions';
import { setReviews, useReviews } from 'store/useReviews';
import ReviewCard from './ReviewCard';

const ReviewsTable = () => {
  const shop = useCurrentShop();
  const dispatch = useDispatch();
  const reviews = useReviews((state: any) => state.reviewsByShop[shop._id]);

  const handleGetReviews = async () => {
    try {
      await tokenHeader();
      const res = await axiosClient.get(`/reviews/shop/${shop._id}`);
      setReviews(res.data.docs, shop._id);
    } catch (error) {
      dispatch(handleErrors(error));
    }
  };

  useEffect(() => {
    handleGetReviews();
  }, []);

  const showReviews = reviews?.slice?.(0, 5);

  return (
    <div className="flex flex-col">
      {showReviews?.map?.((review: any) => (
        <ReviewCard key={review._id} {...review} />
      ))}
    </div>
  );
};

export default ReviewsTable;
