const Burguer1 = () => {
  return (
    <svg
      width="32"
      height="5"
      viewBox="0 0 32 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_514_363" fill="currentColor">
        <path d="M2.9998 2.5C2.9998 2.77614 3.22366 3 3.4998 3H28.4998C28.7759 3 28.9998 2.77614 28.9998 2.5V2.5C28.9998 2.22386 28.7759 2 28.4998 2H3.4998C3.22366 2 2.9998 2.22386 2.9998 2.5V2.5Z" />
      </mask>
      <path
        d="M3.4998 2H28.4998V4H3.4998V2ZM28.4998 3H3.4998V1H28.4998V3ZM3.4998 3C3.77594 3 3.9998 2.77614 3.9998 2.5H1.9998C1.9998 1.67157 2.67138 1 3.4998 1V3ZM27.9998 2.5C27.9998 2.77614 28.2237 3 28.4998 3V1C29.3282 1 29.9998 1.67157 29.9998 2.5H27.9998ZM28.4998 2C28.2237 2 27.9998 2.22386 27.9998 2.5H29.9998C29.9998 3.32843 29.3282 4 28.4998 4V2ZM3.4998 4C2.67137 4 1.9998 3.32843 1.9998 2.5H3.9998C3.9998 2.22386 3.77594 2 3.4998 2V4Z"
        fill="currentColor"
        mask="url(#path-1-inside-1_514_363)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 0H2.5C1.11929 0 0 1.11929 0 2.5C0 3.88071 1.11929 5 2.5 5H29.5C30.8807 5 32 3.88071 32 2.5C32 1.11929 30.8807 0 29.5 0H27V1H29.5C30.3284 1 31 1.67157 31 2.5C31 3.32843 30.3284 4 29.5 4H2.5C1.67157 4 1 3.32843 1 2.5C1 1.67157 1.67157 1 2.5 1H24V0Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Burguer1;
