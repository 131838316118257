import { getSourceMedia } from 'helpers/objectHelpers';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

interface CurrentShopInterface {
  logo: string;
}

const useCurrentShop = () => {
  const { auth, shops } = useSelector((state: RootState) => state);
  const { currentShop } = auth;
  const shop = shops?.docs?.find((doc: any) => doc?._id === currentShop);
  const logoUrl = getSourceMedia(shop?.logo, 'thumbnail');
  shop.logoUrl = logoUrl ?? '';
  return shop;
};

export default useCurrentShop;
