/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import productYupSchema from './productValidation';
import CustomField from 'components/CustomField/CustomField';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { getSelectOptions } from 'helpers/objectHelpers';
import LoadingFullScreen from 'components/LoadingFullScreen/LoadingFullScreen';

import MediaSelector from 'components/MediaSelector/MediaSelector';
import CustomSelectTags from 'components/CustomSelectTags/CustomSelectTags';
import { useEffect, useRef, useState } from 'react';
import CustomSelectCategory from 'components/CustomSelect/CustomSelectCategory';

interface FormProps {
  onSubmit: (values: any, actions: FormikHelpers<any>) => void;
  product?: any;
}

const ProductForm = ({ onSubmit, product }: FormProps) => {
  const ref = useRef();
  const state = useSelector((state: RootState) => state);
  const categories = state.categories.docs;
  const loading = state.products.loading;
  const {
    auth: { currentShop },
  } = state;

  const [initialValues, setInitialValues] = useState({
    name: product?.name || '',
    category: product?.category || '',
    price: product?.price || '',
    discount: product?.discount || '',
    priceWithDiscount: product?.priceWithDiscount || '',
    description: product?.description || '',
    image: product?.image?._id ?? null,
    shop: currentShop,
    stringTags: [],
    tags: product?.tags ?? [],
  });

  useEffect(() => {
    setInitialValues({ ...initialValues, category: product?.category ?? '' });
  }, [product]);

  const handleSubmit = (data: any, actions: FormikHelpers<any>) => {
    onSubmit?.(data, actions);
  };

  const categoryOptions = getSelectOptions(categories);

  return (
    <section className="overflow-y-scroll max-h-[80vh]">
      {loading && <LoadingFullScreen />}
      <Formik
        ref={ref}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={productYupSchema}
      >
        {(formikProps: FormikValues) => {
          const { errors, touched, values, handleChange, setFieldValue } =
            formikProps;
          return (
            <Form className="grid gap-4 max-w-full">
              <CustomField
                type="text"
                name={'name'}
                label="Nombre"
                value={values.name}
                onChange={handleChange}
                placeholder='Ej. "Chicken Burguer'
                error={errors.name}
                touched={touched.name}
                required
              />
              <CustomSelectCategory
                options={categoryOptions}
                name={'category'}
                label="Categoría"
                value={values.category}
                error={errors.category}
                touched={touched.category}
                onChange={setFieldValue}
                placeholder={'Ej. "Bebidas"'}
                productCategory={product?.category}
                setFieldValue={setFieldValue}
                required
              />

              <CustomSelectTags
                name={'tags'}
                value={values.tags}
                error={errors.tags}
                touched={touched.tags}
              />
              <CustomField
                type="number"
                name={'price'}
                label="Precio"
                value={values.price}
                error={errors.price}
                touched={touched.price}
                onChange={handleChange}
                placeholder="5,00"
                required
              />
              <CustomField
                type="text"
                name={'description'}
                label="Descripción"
                value={values.description}
                error={errors.description}
                touched={touched.description}
                onChange={handleChange}
                placeholder={'Ej. "Hamburguesa de pollo con queso y lechuga"'}
                as="textarea"
              />

              <MediaSelector
                name="image"
                setFieldValue={setFieldValue}
                defaultMedium={values.image}
              />
              <button
                type="submit"
                color={'secondary'}
                className="bg-yellow-500 text-primary-50 p-2 rounded-xl font-medium"
              >
                Guardar
              </button>
            </Form>
          );
        }}
      </Formik>
    </section>
  );
};

export default ProductForm;
