import FieldWrapper from 'components/CustomField/FieldWrapper';
import { Field } from 'formik';
import CustomField from './CustomField';
import { Icon } from '@iconify/react';
import { useState } from 'react';

interface CustomFieldPasswordProps {
  type: string;
  name: string;
  value: string;
  placeholder?: string;
  label?: string;
  as?: string;
  error: string;
  touched: boolean;
  required?: boolean;
  onChange: (e: any) => void;
}

const CustomFieldPassword = (props: CustomFieldPasswordProps) => {
  const [type, setType] = useState('password');
  const isPassword = type === 'password';

  return (
    <section className="relative">
      <section className="absolute right-1 bottom-0 w-10 h-10 z-10 grid place-items-center text-black">
        {isPassword ? (
          <button type="button" onClick={() => setType('text')}>
            <Icon icon={'mage:eye'} className="w-6 h-6" />
          </button>
        ) : (
          <button type="button" onClick={() => setType('password')}>
            <Icon icon={'mage:eye-off'} className="w-6 h-6" />
          </button>
        )}
      </section>
      <CustomField {...props} type={type} />
    </section>
  );
};

export default CustomFieldPassword;
