import { useDispatch, useSelector } from 'react-redux';
import ProductItem from './ProductItem';
import useFilterItems from 'hooks/useFilterItems';
import { useEffect, useState } from 'react';
import ProductForm from './ProductForm/ProductForm';
import { createProduct } from 'store/products/productActions';
import { AppDispatch, RootState } from 'store';
import ModalForm from 'components/ModalForm/ModalForm';
import ContainerCenter from 'components/ContainerCenter/ContainerCenter';
import TableHeader from 'components/TableItems/TableHeader';
import TableList from 'components/TableItems/TableList';
import useCurrentShop from 'hooks/useCurrentShop';
import CustomCombobox from 'components/Combobox/CustomCombobox';
import SearchInput from 'components/SearchInput/SearchInput';
import { getDocsByProperty } from 'helpers/objectHelpers';
import { FormikHelpers } from 'formik';

const ProductsPage = () => {
  const dispatch: AppDispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { auth, products, categories } = useSelector(
    (state: RootState) => state,
  );
  const shop = useCurrentShop();
  const sortCategories = shop?.catSort;
  const [selectedCategory, setSelectedCategory] = useState({
    id: '',
    name: '',
  });
  const productsByShop = products?.docsByShop[shop._id];
  const productsByCategory = getDocsByProperty(productsByShop, 'category');

  function getFirstCategoryWithProducts() {
    for (const category of sortCategories) {
      const hasProductsInCategory = productsByShop?.some(
        (product: any) => product.category === category,
      );

      if (hasProductsInCategory) {
        return category;
      }
    }
    return null;
  }

  useEffect(() => {
    const catWithProducts = getFirstCategoryWithProducts() ?? sortCategories[0];
    const cat = {
      id: catWithProducts,
      name: categories?.docsById?.[catWithProducts]?.name,
    };
    setSelectedCategory(cat);
  }, [auth.currentShop, categories]);

  const [filteredProducts, searchText, setSearch]: any = useFilterItems(
    productsByShop,
    ['name', 'description'],
  );

  const onSubmit = async (data: any, actions: FormikHelpers<any>) => {
    await dispatch(createProduct(data));
    setOpen(false);
    actions.resetForm();
    const cat = categories.docsById[data.category];
    setSelectedCategory({ id: cat._id, name: cat.name });
  };

  const categoryProducts = productsByCategory[selectedCategory.id]?.docs;
  const displayProducts = searchText ? filteredProducts : categoryProducts;

  const categoryComboOptions = sortCategories?.map?.((sortCat: string) => {
    const category = categories?.docsById?.[sortCat];
    return {
      id: category?._id,
      name: `${category?.name}`,
    };
  });

  const handleSelectCategory = (option: any) => {
    setSelectedCategory(option);
  };

  return (
    <>
      <ContainerCenter>
        <TableHeader
          title={'Productos'}
          onClick={() => setOpen(true)}
          btnLabel={'Nuevo producto'}
          hideSearch
        />
        <section className="grid lg:grid-cols-2">
          <section>
            <section className="px-6 flex flex-col sm:flex-row items-center gap-3 mt-8">
              {selectedCategory.id && (
                <CustomCombobox
                  options={categoryComboOptions}
                  onChange={handleSelectCategory}
                  value={selectedCategory}
                />
              )}
              <SearchInput searchText={searchText} setSearch={setSearch} />
            </section>
            <TableList>
              {displayProducts?.map?.((product: any) => (
                <ProductItem product={product} key={product._id} />
              ))}
            </TableList>
          </section>
          <section className="mt-6 gap-5 px-6 pb-6 hidden lg:block">
            <section className="p-3 bg-zinc-100 rounded-md border">
              <h3 className="mb-2 text-lg">Nuevo Producto</h3>
              <ProductForm
                onSubmit={onSubmit}
                product={{ category: selectedCategory.id }}
              />
            </section>
          </section>
        </section>
      </ContainerCenter>
      <section className="lg:hidden">
        <ModalForm
          open={open}
          title={'Nuevo producto'}
          onClose={() => setOpen(false)}
        >
          <ProductForm
            onSubmit={onSubmit}
            product={{ category: selectedCategory.id }}
          />
        </ModalForm>
      </section>
    </>
  );
};

export default ProductsPage;
