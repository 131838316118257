/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import CustomField from 'components/CustomField/CustomField';
import { AppDispatch, RootState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import LoadingFullScreen from 'components/LoadingFullScreen/LoadingFullScreen';
import shopValidation from './shopValidation';
import PaymentMethodField from 'components/PaymentMethodField/PaymentMethodField';
import ConfirmAction from 'components/ConfirmAction/ConfirmAction';
import { deleteShop } from 'store/shops/shopActions';
import { useNavigate } from 'react-router-dom';
import { getSourceMedia } from 'helpers/objectHelpers';
import MediaSelector from 'components/MediaSelector/MediaSelector';
import FieldWrapper from 'components/CustomField/FieldWrapper';
import CustomSelectTags from 'components/CustomSelectTags/CustomSelectTags';

interface FormProps {
  onSubmit: (values: any, actions: FormikHelpers<any>) => void;
  doc?: any;
  isUpdate?: boolean;
}

const ShopForm = ({ onSubmit, doc, isUpdate }: FormProps) => {
  const state = useSelector((state: RootState) => state);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const loading = state?.shops?.loading;
  const mainShop = state?.shops?.docs?.find((shop: any) => shop?.isMain);
  const isMainCurrent = mainShop?._id === state?.auth?.currentShop;

  const initialValues = {
    name: doc?.name || '',
    whatsappDelivery: doc?.whatsappDelivery || '',
    schedule: doc?.schedule ?? '',
    paymentMethods: doc?.paymentMethods || [],
    address: doc?.address ?? '',
    gmapLink: doc?.location?.gmapLink ?? '',
    instagram: doc?.instagram ?? '',
    tags: doc?.tagas ?? [],
    bcvRate: doc?.bcvRate ?? 0,
    image: doc?.image?._id ?? null,
    logo: doc?.logo?._id ?? null,
  };

  const handleSubmit = (data: any, actions: FormikHelpers<any>) => {
    const newData = {
      ...data,
      location: {
        ...doc.location,
        gmapLink: data.gmapLink ?? '',
      },
    };
    onSubmit?.(newData, actions);
  };

  const handleDelete = async () => {
    await dispatch(deleteShop(doc._id, mainShop._id));
    navigate('/products');
  };

  return (
    <section>
      {loading && <LoadingFullScreen />}
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={shopValidation}
      >
        {(formikProps: FormikValues) => {
          const { errors, touched, values, handleChange, setFieldValue } =
            formikProps;
          return (
            <Form className="grid gap-4 w-full pb-6">
              {isUpdate && (
                <section className="flex flex-wrap justify-between items-center sticky top-[4.5rem] bg-white z-9 py-1">
                  <h1 className="text-gray-700 text-4xl mb-3 font-semibold">
                    Configuración
                  </h1>

                  <button
                    type="submit"
                    color={'secondary'}
                    className=" w-full sm:w-auto bg-yellow-500 text-primary-50 p-2 rounded-xl font-medium"
                  >
                    Actualizar
                  </button>
                </section>
              )}
              <FieldWrapper label="Imagen de portada" error={errors.image}>
                <MediaSelector
                  name="image"
                  setFieldValue={setFieldValue}
                  defaultMedium={values.image}
                />
              </FieldWrapper>
              <FieldWrapper label="Logo" error={errors.logo}>
                <MediaSelector
                  name="logo"
                  setFieldValue={setFieldValue}
                  defaultMedium={values.logo}
                />
              </FieldWrapper>
              <CustomField
                type="text"
                name={'name'}
                label="Nombre"
                value={values.name}
                onChange={handleChange}
                placeholder='Ej. "Chicken Burguer'
                error={errors.name}
                touched={touched.name}
                required
              />
              <CustomField
                type="text"
                name={'schedule'}
                label="Horario"
                value={values.schedule}
                onChange={handleChange}
                placeholder='Ej. "8:00am - 6:00pm'
                error={errors.schedule}
                touched={touched.schedule}
                required
              />
              <CustomSelectTags
                name={'tags'}
                value={values.tags}
                error={errors.tags}
                touched={touched.tags}
                to={'shop'}
              />
              <CustomField
                type="text"
                name={'whatsappDelivery'}
                label="Whatsapp Delivery"
                value={values.whatsappDelivery}
                onChange={handleChange}
                placeholder='Ej. "+584248390982'
                error={errors.whatsappDelivery}
                touched={touched.whatsappDelivery}
              />

              <CustomField
                type="text"
                name={'instagram'}
                label="Instagram"
                value={values.instagram}
                onChange={handleChange}
                placeholder="mirestaurante"
                error={errors.instagram}
                touched={touched.instagram}
              />

              <CustomField
                type="text"
                name={'gmapLink'}
                label="Enlace Google Maps"
                value={values.gmapLink}
                onChange={handleChange}
                placeholder="https://google.map/"
                error={errors.gmapLink}
                touched={touched.gmapLink}
              />

              <CustomField
                type="text"
                name={'address'}
                label="Dirección escrita"
                value={values.address}
                onChange={handleChange}
                placeholder="Calle 9, Local 5A"
                error={errors.address}
                touched={touched.address}
              />

              {/* <CustomField
                type="number"
                name={'bcvRate'}
                label="Tasa BCV"
                value={values.bcvRate}
                error={errors.bcvRate}
                touched={touched.bcvRate}
                onChange={handleChange}
                placeholder="35.00"
              /> */}
              <PaymentMethodField
                name={'paymentMethods'}
                values={values?.paymentMethods}
                error={errors?.paymentMethods}
                touched={touched?.paymentMethods}
                onChange={setFieldValue}
              />
              {isUpdate && !isMainCurrent && (
                <section className="bg-gray-50 p-2 rounded-md">
                  <h3>Danger Zone</h3>
                  <ConfirmAction onConfirm={handleDelete}>
                    <button
                      type="button"
                      className="text-red-500 text-sm text-center"
                    >
                      Eliminar tienda
                    </button>
                  </ConfirmAction>
                </section>
              )}
              {!isUpdate && (
                <button
                  type="submit"
                  color={'secondary'}
                  className="bg-yellow-500 text-primary-50 p-2 rounded-xl font-medium"
                >
                  Crear
                </button>
              )}
            </Form>
          );
        }}
      </Formik>
    </section>
  );
};

export default ShopForm;
