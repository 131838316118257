import tokenHeader from 'config/tokenHeader';
import * as types from './authTypes';
import axiosClient from 'config/axiosClient';
import { Dispatch } from '@reduxjs/toolkit';
import * as shopTypes from 'store/shops/shopTypes';
import * as productTypes from 'store/products/productTypes';
import { AppDispatch } from 'store';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

export const checkSession: any = () => async (dispatch: Dispatch) => {
  dispatch({ type: types.CHECK_SESSION_START });
  const token = localStorage.getItem('token');
  if (token) {
    dispatch({
      type: types.LOGIN_SUCCESS,
      payload: { token },
    });
  }
  try {
    await tokenHeader();
    const res = await axiosClient.get('/auth/check-session');
    const { user } = res.data;

    dispatch({
      type: types.LOGIN_SUCCESS,
      payload: { token, user },
    });
  } catch (error) {
    // console.log(error);
    dispatch({ type: types.LOGOUT_SUCCESS });
  }
};

interface authLoginProps {
  email: string;
  password: string;
}

interface authCodeProps {
  email: string;
  code: string;
}

export const authLogin: any =
  ({ email, password }: authLoginProps) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: types.LOGIN_START });
    try {
      const res = await axiosClient.post('auth/shop/signin', {
        email,
        password,
      });
      const { token, user, shops, mainShopProducts } = res.data;
      dispatch({
        type: shopTypes.SHOPS_FETCH_SUCCESS,
        payload: shops,
      });
      dispatch({
        type: productTypes.PRODUCTS_FETCH_SUCCESS,
        payload: mainShopProducts,
      });
      dispatch({
        type: types.LOGIN_SUCCESS,
        payload: { token, user, currentShop: shops[0]._id },
      });
    } catch (error) {
      toast.error('Credenciales incorrectas');
      dispatch({
        type: types.LOGIN_ERROR,
        payload: 'Credenciales incorrectas',
      });
    }
  };

export const authCode: any =
  ({ email, code }: authCodeProps) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: types.LOGIN_START });
    try {
      const res = await axiosClient.post('auth/email-verification', {
        email,
        code,
      });
      const { token, user, mainShop } = res.data;
      dispatch({
        type: shopTypes.SHOPS_FETCH_SUCCESS,
        payload: user?.brand?.shops,
      });
      dispatch({
        type: productTypes.PRODUCTS_FETCH_SUCCESS,
        payload: mainShop?.products,
      });
      dispatch({
        type: types.LOGIN_SUCCESS,
        payload: { token, user, currentShop: mainShop._id },
      });
    } catch (error) {
      dispatch({
        type: types.LOGIN_ERROR,
        payload: 'Credenciales incorrectas',
      });
    }
  };

export const authLogout = () => async (dispatch: Dispatch) => {
  dispatch({ type: types.CHECK_SESSION_START });
  try {
    await tokenHeader();
    axiosClient.post('/auth/sign-out');
    dispatch({ type: types.LOGOUT_SUCCESS });
  } catch (error) {
    dispatch({ type: types.LOGOUT_SUCCESS });
  }
};

export const setCurrentShop = (shopId: string) => (dispatch: AppDispatch) => {
  dispatch({ type: types.SET_CURRENT_SHOP, payload: shopId });
};
