import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalForm from 'components/ModalForm/ModalForm';
import { AppDispatch, RootState } from 'store';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import DocActions from 'components/DocItem/DocActions';
import Text from 'components/Text/Text';
import CardItem from 'components/CardItem/CardItem';
import CategoryForm from './CategoryForm/CategoryForm';
import {
  deleteCategory,
  updateCategory,
} from 'store/categories/categoryActions';

interface CategoryItemProps {
  doc: any;
}

const CategoryItem = ({ doc }: CategoryItemProps) => {
  const { name, description } = doc;
  const [published, setPublished] = useState(doc.published);
  const [open, setOpen] = useState(false);
  const { currentShop } = useSelector((state: RootState) => state.auth);
  const dispatch: AppDispatch = useDispatch();
  const categoryId = doc._id;

  const handleUpdate = async (data: any) => {
    await dispatch(updateCategory({ categoryId, shopId: currentShop, data }));
    setOpen(false);
  };

  const handleDelete = () => {
    dispatch(deleteCategory(doc._id, currentShop));
  };

  const handlePublished = () => {
    dispatch(
      updateCategory({
        categoryId,
        shopId: currentShop,
        data: { published: !published },
      }),
    );
    setPublished(!published);
  };

  const mediaSizes = doc?.image?.sizes;
  const thumbnail = mediaSizes?.thumbnail;
  doc.imageUrl = thumbnail?.source_url;

  return (
    <CardItem>
      <section className="flex items-center gap-2">
        <section className="w-12 h-12 bg-gray-100 rounded-md overflow-hidden flex-shrink-0">
          {thumbnail && (
            <img
              src={thumbnail.source_url}
              className="w-full h-full object-cover"
            />
          )}
        </section>
        <section className="w-full">
          <Text type="itemTitle">{name}</Text>
          <Text type="description">{description}</Text>
          <section className="flex justify-between">
            <ToggleSwitch
              checked={published}
              onChange={handlePublished}
              label="Publicado"
            />
            <DocActions
              trashAction={handleDelete}
              editAction={() => setOpen(true)}
            />
          </section>
        </section>
      </section>
      <ModalForm
        open={open}
        onClose={() => setOpen(false)}
        title={'Editar categoría'}
      >
        <CategoryForm doc={doc} onSubmit={handleUpdate} />
      </ModalForm>
    </CardItem>
  );
};

export default CategoryItem;
