import { Icon } from '@iconify/react';
import { QrScanner } from '@yudiel/react-qr-scanner';
import ModalFull from 'components/Modal/ModalFull';
import { appIcons } from 'config/appIcons';

interface ScannerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onScan: (data: any) => void;
  onError: (data: any) => void;
}

const Scanner = ({ open, setOpen, onScan, onError }: ScannerProps) => {
  return (
    <ModalFull open={open} setOpen={setOpen}>
      <section className="w-full h-screen px-3 bg-zinc-900 flex flex-col justify-center">
        <section className="max-w-xl mx-auto w-full">
          <section className="flex justify-end">
            <button onClick={() => setOpen(false)}>
              <Icon icon={appIcons.close} className="w-8 h-8 text-white" />
            </button>
          </section>
          <section className="rounded-lg overflow-hidden bg-white p-2">
            {open && <QrScanner onDecode={onScan} onError={onError} />}
          </section>
        </section>
      </section>
    </ModalFull>
  );
};

export default Scanner;
