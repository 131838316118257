import ContainerCenter from 'components/ContainerCenter/ContainerCenter';
import { useDispatch } from 'react-redux';
import { createShop } from 'store/shops/shopActions';
import { useNavigate } from 'react-router-dom';
import myProducts from './myProducts.json';
import NewShopForm from './ShopForm/NewShopForm';

const NewShopPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (data: any) => {
    await dispatch(createShop(data));
    navigate('/products');
  };

  const newProducts = myProducts.docs.map((product: any) => {
    return {
      name: product.name,
      description: product.description,
      price: product.price,
      category: { name: product.category.name },
    };
  });

  return (
    <ContainerCenter>
      <section className="px-6 py-8">
        <h1 className="text-gray-700 text-4xl mb-3 font-semibold">
          Nueva sucursal
        </h1>
        <NewShopForm onSubmit={handleSubmit} />
      </section>
    </ContainerCenter>
  );
};

export default NewShopPage;
