import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { Icon } from '@iconify/react';
import { setCurrentShop } from 'store/auth/authActions';
import { Link, useNavigate } from 'react-router-dom';
import { getProductsByshop } from 'store/products/productActions';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

interface ShopSelectorProps {
  onClick?: () => void;
}

const ShopSelector = ({ onClick }: ShopSelectorProps) => {
  const { shops, auth } = useSelector((state: RootState) => state);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { docs } = shops;
  const currentShop = auth.currentShop;

  const handleSetShop = (shopId: string) => {
    dispatch(setCurrentShop(shopId));
    dispatch(getProductsByshop(shopId));
    navigate('/products');
    onClick?.();
  };

  const isShops = docs?.length > 0;

  return (
    <>
      {isShops && (
        <section className="mb-2">
          {docs.map((shop: any) => (
            <section key={shop._id}>
              <button
                onClick={() => handleSetShop(shop._id)}
                className={classNames(
                  currentShop === shop._id
                    ? 'text-yellow-500'
                    : 'text-gray-700',
                  'px-4 py-1 w-full text-left flex items-center gap-2',
                )}
              >
                <picture className="block w-6 h-6 bg-gray-300 rounded-full" />
                <span className="text-sm font-semibold flex-1 whitespace-nowrap">
                  {shop.name}
                </span>
                <input
                  type="radio"
                  className="w-5 h-5"
                  defaultChecked={currentShop === shop._id}
                />
              </button>
            </section>
          ))}
          <Link className="px-4 flex items-center gap-2 mt-3" to={'/new-shop'}>
            <picture className="w-6 h-6 bg-yellow-500 rounded-full grid place-content-center">
              <Icon
                icon="fluent:add-12-filled"
                className="w-4 h-4 text-yellow-500"
              />
            </picture>
            <span>Crear nueva sucursal</span>
          </Link>
        </section>
      )}
    </>
  );
};

export default ShopSelector;
