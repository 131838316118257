/* eslint-disable no-extra-boolean-cast */
import ContainerCenter from 'components/ContainerCenter/ContainerCenter';
import ReviewsTable from './components/ReviewsTable';
import { useEffect, useState } from 'react';
import axiosClient from 'config/axiosClient';
import useCurrentShop from 'hooks/useCurrentShop';
import LoadingFullScreen from 'components/LoadingFullScreen/LoadingFullScreen';
import Scanner from 'components/Scanner/Scanner';
import RewardScanned from './components/RewardScanned';
import ShopHealth from './components/ShopHealth';
import ChartBar from 'components/Chart/ChartBar';
import ChartCard from './components/ChartCard';
import { fetchUnpaidOrders } from 'store/useOrders';
import DashboardHeader from './components/DashboardHeader';

const DashboardPage = () => {
  const shop = useCurrentShop();
  const [openScanner, setOpenScanner] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rewardData, setRewardData]: any = useState({});
  const [openReward, setOpenReward] = useState(false);

  useEffect(() => {
    fetchUnpaidOrders(shop._id);
  }, []);

  const handleScan = async (data: any) => {
    if (data) {
      try {
        setOpenScanner(false);
        setLoading(true);
        const res = await axiosClient.put(
          `/user-rewards/qrcode/${data}/shop/${shop._id}`,
        );
        setRewardData(res.data);
        setLoading(false);
        setOpenReward(true);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleError = (err: any) => {
    console.error(err);
  };

  const reward = rewardData?.reward;
  const pointProfile = rewardData?.pointProfile;

  return (
    <>
      <ContainerCenter>
        <DashboardHeader
          title={shop?.name ?? ''}
          btnLabel={'Escanear premio'}
          onClick={() => setOpenScanner(true)}
          hideSearch
        />
        {loading && <LoadingFullScreen />}
        <Scanner
          open={openScanner}
          setOpen={setOpenScanner}
          onScan={handleScan}
          onError={handleError}
        />
        <RewardScanned
          open={openReward}
          setOpen={setOpenReward}
          reward={reward}
          pointProfile={pointProfile}
        />
        <section className="px-3 flex flex-col items-end gap-3 mt-8 pb-6">
          <section className="grid grid-cols-12 gap-2 w-full">
            <ChartCard title="Salud del sitio" className="col-span-12">
              <ShopHealth />
            </ChartCard>

            <ChartCard
              className="mt-2 col-span-12 lg:col-span-6"
              title="Análisis de valoraciones"
            >
              <ChartBar />
            </ChartCard>

            <ChartCard title="Reviews" className="col-span-12 lg:col-span-6">
              <ReviewsTable />
            </ChartCard>
          </section>
        </section>
      </ContainerCenter>
    </>
  );
};

export default DashboardPage;
