import CustomRadioGroup from 'components/Form/CustomRadioGroup';
import LoadingFullScreen from 'components/LoadingFullScreen/LoadingFullScreen';
import axiosClient from 'config/axiosClient';
import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'store';
import { orderPaidValidation } from './orderNumberValidation';
import DefaultLayout from 'components/Layout/DefaultLayout';
import ContainerCenter from 'components/ContainerCenter/ContainerCenter';

const OrderNumberPage = () => {
  const params = useParams();
  const { isAuth } = useSelector((state: RootState) => state.auth);
  const [loading, setLoading] = useState(false);
  const [order, setOrder]: any = useState(null);
  const [error, setError] = useState(false);

  const handleGetOrder = async () => {
    try {
      setLoading(true);
      const res = await axiosClient.get(
        `/orders/order-number/${params.orderNumber}`,
      );
      console.log(res.data.order);
      setOrder(res.data.order);
      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetOrder();
  }, []);

  const initialValues = {
    paid: '',
  };

  const handleSubmit = async (data: any, actions: FormikHelpers<any>) => {
    try {
      const res = await axiosClient.put(
        `/orders/order-number/${order.orderNumber}/confirm-as-paid`,
      );
      console.log(res.data);
      setOrder(res.data.order);
    } catch (error) {
      console.log(error);
    }
  };

  const UserLayout = ({ children }: any) => <> {children}</>;

  const profile = order?.user?.profile;
  const shop = order?.shop;
  const OrderLayout = isAuth ? DefaultLayout : UserLayout;

  return (
    <OrderLayout>
      <ContainerCenter>
        <section className="bg-white">
          {loading && <LoadingFullScreen />}
          <section className="p-3 bg-gray-400 text-center grid place-content-center">
            <p className="text-white text-xl">{shop?.name}</p>
          </section>

          <section className="p-3 text-center">
            <h1 className="font-bold text-lg">Detalles de la orden:</h1>

            <p className="text-gray-600 text-xl mt-3">{profile?.name}</p>
            <span className="font-bold mt-1 block">{order?.orderNumber}</span>
          </section>

          <section className="p-3 grid gap-2">
            <section className="flex gap-1">
              <p className="font-bold">Fecha:</p>
              <p>{order?.createdAt}</p>
            </section>

            <section className="flex gap-1">
              <p className="font-bold">Tipo de orden:</p>
              <p>{order?.receivingType}</p>
            </section>

            <section className="flex gap-1">
              <p className="font-bold">Método de pago:</p>
              <p>{order?.paymentMethod}</p>
            </section>

            <section>
              <p className="font-bold">Comentario adicional:</p>
              <p className="capitalize">{order?.comment}</p>
            </section>
          </section>

          <h1 className="font-bold px-3 mt-2">Detalle de la orden:</h1>
          <section className="mx-3 px-1 bg-gray-700 text-white flex justify-between">
            <p>Producto</p>
            <p>Precio Total</p>
          </section>
          <section className="px-3 grid gap-1 mt-1">
            {order?.products?.map?.((product: any) => (
              <section
                className="flex justify-between"
                key={product.product._id}
              >
                <p className="">
                  {product.name} x {product.quantity}
                </p>
                <p className="capitalize">${product?.totalPrice}</p>
              </section>
            ))}
            <section className="flex justify-end gap-1 mt-3">
              <p className="font-bold text-lg">Total:</p>
              <p className="text-lg">${order?.total}</p>
            </section>
          </section>

          <section className="flex gap-1 px-3 mt-3">
            <p className="font-bold">Estado del pago:</p>
            <p className="capitalize">{order?.paid ? 'Pagada' : 'Pendiente'}</p>
          </section>
          {isAuth && (
            <section className="px-3">
              {!order?.paid && (
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  validationSchema={orderPaidValidation}
                >
                  {(formikProps: FormikValues) => {
                    const {
                      errors,
                      touched,
                      values,
                      handleChange,
                      setFieldValue,
                    } = formikProps;
                    return (
                      <Form className="grid gap-4 max-w-full">
                        <CustomRadioGroup
                          type="text"
                          name={'paid'}
                          value={values.paid}
                          onChange={setFieldValue}
                          placeholder='Ej. "Delivery'
                          error={errors.paid}
                          touched={touched.paid}
                          options={['Pagada']}
                        />
                        <button
                          type="submit"
                          color={'secondary'}
                          className="bg-yellow-500 text-black p-2 rounded-xl text-lg font-bold"
                        >
                          Confirmar
                        </button>
                      </Form>
                    );
                  }}
                </Formik>
              )}
            </section>
          )}
        </section>
      </ContainerCenter>
    </OrderLayout>
  );
};

export default OrderNumberPage;
