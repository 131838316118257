import { useDispatch, useSelector } from 'react-redux';
import useFilterItems from 'hooks/useFilterItems';
import { useState } from 'react';
import CategoryForm from './CategoryForm/CategoryForm';
import { AppDispatch, RootState } from 'store';
import ModalForm from 'components/ModalForm/ModalForm';
import ContainerCenter from 'components/ContainerCenter/ContainerCenter';
import TableHeader from 'components/TableItems/TableHeader';
import TableList from 'components/TableItems/TableList';
import { createCategory } from 'store/categories/categoryActions';
import { DndProvider } from 'react-dnd';
import ReorderableList from 'components/ReorderableList/ReorderableList';
import { HTML5Backend } from 'react-dnd-html5-backend';

const CategoriesPage = () => {
  const [open, setOpen] = useState(false);
  const { categories, auth } = useSelector((state: RootState) => state);
  const { docs } = categories;
  const [filteredDocs, searchText, setSearch]: any = useFilterItems(docs, [
    'name',
    'description',
  ]);
  const dispatch: AppDispatch = useDispatch();

  const onSubmit = async (data: any) => {
    await dispatch(createCategory(data, auth.currentShop));
    setOpen(false);
  };

  return (
    <>
      <ContainerCenter>
        <TableHeader
          title={'Categorías'}
          onClick={() => setOpen(true)}
          btnLabel={'Nueva categoría'}
          searchText={searchText}
          setSearch={setSearch}
          hideSearch
        />
        <TableList>
          <DndProvider backend={HTML5Backend}>
            <ReorderableList />
          </DndProvider>
        </TableList>
      </ContainerCenter>
      <ModalForm
        open={open}
        title={'Nueva categoría'}
        onClose={() => setOpen(false)}
      >
        <CategoryForm onSubmit={onSubmit} />
      </ModalForm>
    </>
  );
};

export default CategoriesPage;
