const ShowRating = ({ className, value }: any) => {
  return (
    <section className={`${className} flex gap-1`}>
      {new Array(5).fill({}).map((props, index) => {
        const rate = index + 1;
        return (
          <span
            key={rate}
            className={`${
              value === rate
                ? 'text-gray-200 bg-gray-600'
                : 'text-gray-400 bg-gray-100'
            }  text-lg font-bold py-1 flex-1 rounded-md grid place-content-center items-center`}
          >
            {rate}
          </span>
        );
      })}
    </section>
  );
};

export default ShowRating;
