import { useEffect } from 'react';
import ContainerCenter from 'components/ContainerCenter/ContainerCenter';
import TableHeader from 'components/TableItems/TableHeader';
import TableList from 'components/TableItems/TableList';
import useCurrentShop from 'hooks/useCurrentShop';
import axiosClient from 'config/axiosClient';
import { Link } from 'react-router-dom';
import { setOrders, useOrders } from 'store/useOrders';

const OrdersPage = () => {
  const shop = useCurrentShop();
  const ordersByShop: any = useOrders((state) => state.ordersByShop);
  const orders = ordersByShop[shop._id];

  const handleGetUnpaidOrders = async () => {
    try {
      const res = await axiosClient.get(`/orders/shop/${shop._id}/unpaid`);
      setOrders(res.data.docs, shop._id);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetUnpaidOrders();
  }, []);

  return (
    <>
      <ContainerCenter>
        <TableHeader title={'Órdenes pendientes'} hideSearch />
        <TableList>
          {orders?.map((order: any) => (
            <Link
              to={`/order/${order.orderNumber}`}
              key={order._id}
              className="shadow-sm p-2 rounded-lg border border-zinc-100 flex justify-between"
            >
              <section className="flex flex-col">
                <span className="font-bold">{order.orderNumber}</span>
                <span>{order.user.profile.name}</span>
              </section>
              <section className="flex">
                <span className="font-bold">${order.total}</span>
              </section>
            </Link>
          ))}
        </TableList>
      </ContainerCenter>
    </>
  );
};

export default OrdersPage;
