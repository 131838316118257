interface FieldWrapperProps {
  label?: string;
  required?: boolean;
  error: string;
  children: JSX.Element;
}

const FieldWrapper = ({
  label = '',
  error,
  required,
  children,
}: FieldWrapperProps) => {
  return (
    <section className="w-full">
      <section className="between w-full">
        <label className="text-slate-600 font-semibold text-sm">{label}</label>
        {error ? (
          <span className="text-red-500">{error}</span>
        ) : (
          required && <span className="text-slate-600 text-sm">Requerido</span>
        )}
      </section>
      {children}
    </section>
  );
};

export default FieldWrapper;
