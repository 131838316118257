export const sendWhatsappMessage = (message: string) => {
  const url = `https://api.whatsapp.com/send?phone=5492215060652&text=${message}`;
  window.open(url, '_blank');
};

export const handleGetMenew = () => {
  const message = 'Hola, quiero obtener Menew para mi negocio';
  sendWhatsappMessage(message);
};

export const handleGetCompanyPlan = () => {
  const message = 'Hola, estoy interesado en obtener Menew Company';
  sendWhatsappMessage(message);
};

export const handleGetEnterprise = () => {
  const message = 'Hola, estoy interesado en obtener Menew Enterprise ';
  sendWhatsappMessage(message);
};

export const handleGetGestionDemo = () => {
  const message =
    'Hola, me gustaría obtener una demo de la plataforma de gestión';
  sendWhatsappMessage(message);
};

export const handleContact = () => {
  const message =
    'Hola, me interesa concer más de Menew o de los productos que ofrecen';
  sendWhatsappMessage(message);
};

export const handleGetWhatsappLink = (message: string) => {
  const url = `https://api.whatsapp.com/send?phone=5492215060652&text=${message}`;
  return url;
};

export const handleGetContactHref = () => {
  const message =
    'Hola, me interesa concer más de Menew o de los productos que ofrecen';
  const link = handleGetWhatsappLink(message);
  return link as unknown as string;
};

export const handleGetGestionDemoLink = () => {
  const message =
    'Hola, me interesa obtener una demo de la plataforma de gestión';
  const link = handleGetWhatsappLink(message);
  return link as unknown as string;
};
