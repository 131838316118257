import FieldWrapper from 'components/CustomField/FieldWrapper';
import { Field, FormikHelpers } from 'formik';
import { useState } from 'react';
import MethodForm from './MethodForm';
import Modal from 'components/Modal/Modal';
import ModalForm from 'components/ModalForm/ModalForm';
import MethodItem from './MethodItem';
import { UilPlusCircle } from '@iconscout/react-unicons';

interface PaymentMethodFieldProps {
  name: string;
  values: [];
  error: string;
  touched: boolean;
  required?: boolean;
  onChange: (key: any, value: any) => void;
}

const PaymentMethodField = (props: PaymentMethodFieldProps) => {
  const { name, values = [], error, touched, onChange } = props;
  const [newMethod, setNewMethod] = useState<boolean>(false);
  const isError = error && touched;
  const baseClass = 'border-2 border-gray-300 p-2 rounded-xl resize-none';
  const className = `${baseClass} ${
    isError ? 'border-red-500' : touched ? 'border-green-700' : ''
  }`;

  const handleAddMethod = (data: any) => {
    onChange?.(name, [data, ...values]);
    setNewMethod(false);
  };

  return (
    <section className="border-2 p-2 rounded-md">
      {error && <span className="text-red-500">{error}</span>}
      <section className="between">
        <label className="text-slate-600 font-bold">{'Métodos de pago'}</label>
        <button type="button" onClick={() => setNewMethod(true)}>
          <UilPlusCircle />
        </button>
      </section>
      <section className="grid gap-1 mt-2">
        {values?.map?.((item, index) => (
          <MethodItem
            key={item}
            method={item}
            index={index}
            name={name}
            values={values}
            onChange={onChange}
          />
        ))}
      </section>
      <ModalForm
        open={newMethod}
        title="Método de pago"
        onClose={() => setNewMethod(false)}
      >
        <MethodForm onSubmit={handleAddMethod} />
      </ModalForm>
    </section>
  );
};

export default PaymentMethodField;
