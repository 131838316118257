import { UilTimes } from '@iconscout/react-unicons';

interface SelectedOptionProps {
  className: string;
  label: string;
  onClick: () => void;
}

const SelectedOption = ({
  className,
  label = '',
  onClick,
}: SelectedOptionProps) => {
  return (
    <section className="relative">
      <input
        className={`disabled:bg-white ${className}`}
        disabled
        value={label}
      />
      <button
        type="button"
        className="absolute right-0 p-1 mt-[0.33rem] mr-2 text-slate-700 rounded-md"
        onClick={onClick}
      >
        <UilTimes />
      </button>
    </section>
  );
};

export default SelectedOption;
