import { useCallback, useState, FC, useEffect } from 'react';
import update from 'immutability-helper';

import Card from './Card';
import CategoryItem from 'pages/Categories/CategoryItem';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { updateCategorySort } from 'store/categories/categoryActions';
import useCurrentShop from 'hooks/useCurrentShop';

export interface Item {
  _id: number;
  text: string;
}

export interface ContainerState {
  cards: Item[];
}

interface ListProps {
  docs: any;
}

const ReorderableList = () => {
  const { docsById, docs } = useSelector(
    (state: RootState) => state.categories,
  );
  const dispatch: AppDispatch = useDispatch();
  const shop = useCurrentShop();
  const sort = shop.catSort ?? [];
  const [cards, setCards] = useState(sort ?? []);

  useEffect(() => {
    setCards(sort);
  }, [sort]);

  const onDrop = useCallback(() => {
    setCards((prevCards: Item[]) => {
      dispatch(updateCategorySort(prevCards, shop._id));
      return prevCards;
    });
  }, []);

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setCards((prevCards: Item[]) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex] as Item],
        ],
      }),
    );
  }, []);

  const renderCard = useCallback(
    (catId: string, index: number) => {
      return (
        <Card
          key={catId}
          index={index}
          id={catId}
          moveCard={moveCard}
          onDrop={onDrop}
        >
          {docsById?.[catId] && <CategoryItem doc={docsById?.[catId]} />}
        </Card>
      );
    },
    [docsById],
  );

  return (
    <>
      <div className="w-full">
        {cards?.map?.((catId: any, i: number) => renderCard(catId, i))}
      </div>
    </>
  );
};

export default ReorderableList;
