import { UilTimes } from '@iconscout/react-unicons';
import Modal from 'components/Modal/Modal';

interface ModalFormProps {
  open: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
}

const ModalForm = ({ open, onClose, title, children }: ModalFormProps) => {
  return (
    <>
      {open && (
        <section
          className={`fixed inset-0 z-30 grid place-content-center place-items-center ${
            open ? 'block' : 'hidden'
          }`}
        >
          <section
            className="bg-black-2 bg-opacity-80 absolute inset-0"
            onClick={onClose}
          />
          <section className="relative p-3">
            <section className="flex justify-center w-full cursor-default px-5 pb-5 relative max-w-md mx-auto bg-white rounded-md">
              <section className="max-w-lg w-full">
                <header className="py-6">
                  <section className="between text-slate-600">
                    <h3>{title}</h3>
                    <button
                      type="button"
                      onClick={onClose}
                      className="rounded-md hover:bg-gray-50 transition"
                    >
                      <UilTimes className="text-yellow-500 w-8 h-8" />
                    </button>
                  </section>
                </header>
                <section>{children}</section>
              </section>
            </section>
          </section>
        </section>
      )}
    </>
  );
};

export default ModalForm;
