import { Icon } from '@iconify/react';
import { appIcons } from 'config/appIcons';
import useCurrentShop from 'hooks/useCurrentShop';

const ShopHealth = () => {
  const shop = useCurrentShop();
  shop.rating = 5;
  const healtTable: any = {
    0: 'bad',
    1: 'bad',
    2: 'regular',
    3: 'regular',
    4: 'good',
    5: 'good',
  };
  const health = healtTable[shop.rating] ?? '';
  return (
    <section>
      <section className="grid grid-cols-3 gap-2 place-items-center text-zinc-100">
        <Icon
          icon={appIcons.sadFace}
          className={`w-full h-full max-w-30 ${
            health === 'bad' ? 'text-red-600' : ''
          }`}
        />

        <Icon
          icon={appIcons.straightFace}
          className={`w-full h-full max-w-30 ${
            health === 'regular' ? 'text-yellow-500' : ''
          }`}
        />

        <Icon
          icon={appIcons.happyFace}
          className={`w-full h-full max-w-30 ${
            health === 'good' ? 'text-green-600' : ''
          }`}
        />
      </section>
      <span className="mt-8 block text-lg text-green-600 bg-green-50 p-2 rounded-lg">
        Las reseñas son predominantemente positivas. Los clientes tienen una
        experiencia mayormente satisfactoria en el restaurante.
      </span>
    </section>
  );
};

export default ShopHealth;
