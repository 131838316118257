/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import CustomField from 'components/CustomField/CustomField';
import { AppDispatch, RootState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import LoadingFullScreen from 'components/LoadingFullScreen/LoadingFullScreen';
import shopValidation from './shopValidation';
import PaymentMethodField from 'components/PaymentMethodField/PaymentMethodField';
import ConfirmAction from 'components/ConfirmAction/ConfirmAction';
import { deleteShop } from 'store/shops/shopActions';
import { setCurrentShop } from 'store/auth/authActions';
import { useNavigate } from 'react-router-dom';
import InputFileCustomView from 'components/CustomInputFile/InputFileCustomView';
import { createFormData, getSourceMedia } from 'helpers/objectHelpers';

interface FormProps {
  onSubmit: (values: any, actions: FormikHelpers<any>) => void;
  doc?: any;
  isUpdate?: boolean;
}

const NewShopForm = ({ onSubmit, doc, isUpdate }: FormProps) => {
  const state = useSelector((state: RootState) => state);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const loading = state?.shops?.loading;
  const mainShop = state?.shops?.docs?.find((shop: any) => shop?.isMain);
  const isMainCurrent = mainShop?._id === state?.auth?.currentShop;

  const initialValues = {
    name: doc?.name || '',
    whatsappDelivery: doc?.whatsappDelivery || '',
    schedule: doc?.schedule ?? '',
  };

  const handleSubmit = (data: any, actions: FormikHelpers<any>) => {
    const formData = createFormData(data);
    onSubmit?.(formData, actions);
  };

  const imageUrl = getSourceMedia(doc?.image, 'full');
  const logoUrl = getSourceMedia(doc?.logo, 'thumbnail');

  return (
    <section>
      {loading && <LoadingFullScreen />}
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={shopValidation}
      >
        {(formikProps: FormikValues) => {
          const { errors, touched, values, handleChange, setFieldValue } =
            formikProps;
          return (
            <Form className="grid gap-4 w-full pb-6">
              {isUpdate && (
                <section className="flex justify-between items-center sticky top-[4.5rem] bg-white z-9 py-1">
                  <h1 className="text-gray-700 text-4xl mb-3 font-semibold">
                    Configuración
                  </h1>

                  <button
                    type="submit"
                    color={'secondary'}
                    className="bg-yellow-500 text-primary-50 p-2 rounded-xl font-medium"
                  >
                    Actualizar
                  </button>
                </section>
              )}
              <CustomField
                type="text"
                name={'name'}
                label="Nombre"
                value={values.name}
                onChange={handleChange}
                placeholder='Ej. "Chicken Burguer'
                error={errors.name}
                touched={touched.name}
                required
              />
              <CustomField
                type="text"
                name={'schedule'}
                label="Horario"
                value={values.schedule}
                onChange={handleChange}
                placeholder='Ej. "8:00am - 6:00pm'
                error={errors.schedule}
                touched={touched.schedule}
                required
              />
              <CustomField
                type="text"
                name={'whatsappDelivery'}
                label="Whatsapp Delivery"
                value={values.whatsappDelivery}
                onChange={handleChange}
                placeholder='Ej. "+584248390982'
                error={errors.whatsappDelivery}
                touched={touched.whatsappDelivery}
              />
              <button
                type="submit"
                color={'secondary'}
                className="bg-yellow-500 text-primary-50 p-2 rounded-xl font-medium"
              >
                Crear
              </button>
            </Form>
          );
        }}
      </Formik>
    </section>
  );
};

export default NewShopForm;
