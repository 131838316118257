export const appIcons = {
  shop: 'bxs:store-alt',
  exp: 'fluent-emoji:sparkles',
  gems: 'fluent-emoji:gem-stone',
  trophy: 'fluent-emoji:trophy',
  check: 'lets-icons:check-fill',
  bad: 'icon-park-solid:bad-two',
  dashboard: 'ri:dashboard-line',
  product: 'ep:food',
  gift: 'iconamoon:gift',
  upload: 'mi:cloud-upload',
  close: 'iconamoon:close-bold',
  desktop: 'carbon:virtual-desktop',
  sadFace: 'streamline:sad-face',
  happyFace: 'streamline:smiley-happy',
  smirkFace: 'streamline:smiley-smirk',
  straightFace: 'streamline:straight-face',
  comment: 'ant-design:comment-outlined',
  commentHeart: 'uil:comment-heart',
  order: 'carbon:order-details',
};
