import { Icon } from '@iconify/react';
import { appIcons } from 'config/appIcons';
import axiosClient from 'config/axiosClient';
import tokenHeader from 'config/tokenHeader';
import { useEffect, useState } from 'react';
import { setMedia, useMediaStore } from 'store/useMediaStore';
import MediaModal from './MediaModal';

interface MediaSelectorProps {
  onChange?: any;
  setFieldValue?: any;
  name?: string;
  defaultMedium?: string;
}

const MediaSelector = ({
  onChange,
  setFieldValue,
  name,
  defaultMedium,
}: MediaSelectorProps) => {
  const [mediumValue, setMediumValue]: any = useState(defaultMedium);
  const [open, setOpen] = useState(false);
  const media = useMediaStore((state: any) => state.media);

  const handleGetMedios = async () => {
    try {
      await tokenHeader();
      const res = await axiosClient.get('/media');
      setMedia(res.data.docs);
    } catch (error) {
      console.log('');
    }
  };

  useEffect(() => {
    handleGetMedios();
  }, []);

  const handleSelect = (medium: any) => {
    onChange?.(medium);
    setFieldValue?.(name, medium._id);
    setMediumValue(medium._id);
    setOpen(false);
  };

  const handleRemove = () => {
    onChange?.(null);
    setFieldValue?.(name, null);
    setMediumValue(null);
  };

  const mediumSource =
    mediumValue && media.find((medium: any) => medium._id === mediumValue);

  return (
    <>
      <MediaModal open={open} setOpen={setOpen} onChange={handleSelect} />
      <section className="flex flex-col w-full border-2 pt-5 pb-3 px-4 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100">
        <button type="button" onClick={() => setOpen(true)}>
          <section className="flex w-full items-center gap-6 flex-wrap justify-center">
            {mediumSource ? (
              <>
                <section>
                  <section className="p-2 aspect-video relative w-52 rounded-md overflow-hidden">
                    <img
                      src={mediumSource.sizes.thumbnail.source_url}
                      className="absolute inset-0 w-full h-full object-cover"
                    />
                  </section>
                </section>
              </>
            ) : (
              <Icon
                icon={appIcons.upload}
                className="w-10 h-10 text-zinc-400"
              />
            )}
            <section>
              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                <span className="font-semibold">
                  Click para seleccionar una imagen
                </span>
              </p>
              <p className="text-xs text-gray-500 dark:text-gray-400">
                PNG o JPG (MAX. 2mb)
              </p>
            </section>
          </section>
        </button>
        {mediumSource && (
          <button
            type="button"
            onClick={handleRemove}
            className=" bg-red-100 text-red-500 py-1 px-2 rounded-md mt-3 hover:bg-red-200 hover:text-red-600 w-full flex justify-center"
          >
            <section className="block w-full max-w-[220px] truncate">
              Remover imagen
            </section>
          </button>
        )}
      </section>
    </>
  );
};

export default MediaSelector;
