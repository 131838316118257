import FieldWrapper from 'components/CustomField/FieldWrapper';
import countries from 'mocks/CountryCodes.json';
import countryRegions from 'mocks/RegionsByShortCode.json';

interface CustomProps {
  name: string;
  values?: any;
  placeholder?: string;
  label?: string;
  errors?: any;
  touched?: any;
  required?: boolean;
  setFieldValue?: any;
}

const CountrySelector = ({
  name,
  values,
  errors,
  touched,
  label,
  setFieldValue,
}: CustomProps) => {
  const value = values?.[name] ?? 'AF';
  const stateValue = values?.['state'];
  const isTouched = touched?.[name];
  const error = isTouched && errors?.[name];
  const options = countries.map((country) => ({
    label: country.name,
    value: country.code,
  }));

  const regions: any = countryRegions;

  const handleChange = (e: any) => {
    setFieldValue(name, e.target.value);
  };

  const onChangeState = (e: any) => {
    setFieldValue('state', e.target.value);
  };

  const filterCountry: any = regions[value];
  const regionOptions = Object.values(filterCountry?.regions).map(
    (region: any) => ({
      label: region.name,
      value: region.shortCode,
    }),
  );

  return (
    <FieldWrapper error={error} label={label}>
      <section className="w-full grid gap-2">
        <select
          value={value}
          className="py-2.5 px-2 border border-zinc-200 rounded-lg break-words whitespace-pre-wrap w-full"
          onChange={handleChange}
        >
          {options?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <select
          value={stateValue}
          className="py-2.5 px-2 border border-zinc-200 rounded-lg"
          onChange={onChangeState}
        >
          {regionOptions?.map((option: any) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </section>
    </FieldWrapper>
  );
};

export default CountrySelector;
