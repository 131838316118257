import LoadingFullScreen from 'components/LoadingFullScreen/LoadingFullScreen';
import Scanner from 'components/Scanner/Scanner';
import axiosClient from 'config/axiosClient';
import { useState } from 'react';
import toast from 'react-hot-toast';

interface ValidateReviewScannerProps {
  open: boolean;
  setOpen: (boolean: boolean) => void;
}

const ValidateReviewScanner = ({
  open,
  setOpen,
}: ValidateReviewScannerProps) => {
  const [loading, setLoading] = useState(false);
  const onScan = async (reviewId: string) => {
    try {
      setOpen(false);
      setLoading(true);
      const res = await axiosClient.put(`/reviews/approve/${reviewId}`);
      setLoading(false);
      toast.success('Reseña validada exitósamente');
    } catch (error) {
      toast.error('QR inválido o ya fue aprobada la reseña');
      setLoading(false);
    }
  };

  const onError = (error: any) => {
    console.log(error);
  };

  return (
    <section>
      {loading && <LoadingFullScreen />}
      <Scanner
        open={open}
        setOpen={setOpen}
        onScan={onScan}
        onError={onError}
      />
    </section>
  );
};

export default ValidateReviewScanner;
