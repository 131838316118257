/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import CustomField from 'components/CustomField/CustomField';
import methodValidation from './methodValidation';
import { textToSlug } from 'helpers/stringHelpers';

interface FormProps {
  onSubmit: (values: any, actions: FormikHelpers<any>) => void;
  method?: any;
}

const MethodForm = ({ onSubmit, method }: FormProps) => {
  const initialValues = {
    name: method?.name || '',
    description: method?.description || '',
  };

  const handleClick = async (formikProps: FormikValues) => {
    const errors = await formikProps.validateForm();
    const isValid = Object.keys(errors).length === 0;

    if (isValid) {
      const { values } = formikProps;
      const data = { ...values, slug: textToSlug(values.name) };
      onSubmit?.(data, {} as FormikHelpers<any>);
    }
  };

  return (
    <section className="p-2">
      <Formik
        initialValues={initialValues}
        onSubmit={() => {}}
        validationSchema={methodValidation}
      >
        {(formikProps: FormikValues) => {
          const { errors, touched, values, handleChange } = formikProps;
          return (
            <Form className="grid gap-4">
              <CustomField
                type="text"
                name={'name'}
                label="Nombre"
                value={values.name}
                onChange={handleChange}
                placeholder='Ej. "Zelle"'
                error={errors.name}
                touched={touched.name}
                required
              />
              <CustomField
                type="text"
                name={'description'}
                label="Descripción"
                value={values.description}
                onChange={handleChange}
                placeholder='Ej. "Correo: correozelle@gmail.com"'
                error={errors.description}
                touched={touched.description}
                as="textarea"
                required
              />
              <button
                onClick={() => handleClick(formikProps)}
                type="button"
                color={'secondary'}
                className="bg-yellow-500 text-primary-50 p-2 rounded-xl font-medium"
              >
                {method ? 'Guardar' : 'Agregar'}
              </button>
            </Form>
          );
        }}
      </Formik>
    </section>
  );
};

export default MethodForm;
