import Modal from 'components/Modal/Modal';
import { useState } from 'react';
import ShowRating from './ShowRating';
import { appIcons } from 'config/appIcons';
import { Icon } from '@iconify/react';
import ShopLogo from 'components/Logo/ShopLogo';

const ReviewCard = ({ product, comment, rating }: any) => {
  const [open, setOpen] = useState(false);
  const productImage = product?.image?.sizes;
  const isGood = rating > 3.5;
  const onClose = () => setOpen(false);

  return (
    <>
      <Modal open={open} setOpen={setOpen} panelClass="bg-white max-w-sm">
        {productImage && (
          <img
            className="h-52 w-full object-cover"
            src={productImage?.full?.source_url}
          />
        )}
        <section className="px-6 py-5">
          <h2 className="font-bold text-xl mt-3">{product.name}</h2>
          <p>
            {isGood ? (
              <span className="bg-green-50 text-green-700 px-0.5 rounded-full">
                Ha gustado
              </span>
            ) : (
              <span className="bg-yellow-50 text-yellow-600 px-0.5 rounded-full">
                No ha gustado tanto
              </span>
            )}{' '}
            recibiste {rating} puntos
          </p>
          <ShowRating className="mt-1" value={rating} />
          <p className="mt-4">{comment}</p>
          {isGood ? (
            <button
              onClick={onClose}
              className="bg-green-700 text-white rounded-full mt-4 w-full p-3 font-bold text-xl"
            >
              Buenísimo
            </button>
          ) : (
            <button
              onClick={onClose}
              className="bg-red-700 text-white rounded-full mt-4 w-full p-3 font-bold text-xl"
            >
              Lo tendré en cuenta
            </button>
          )}
        </section>
      </Modal>
      <button
        onClick={() => setOpen(true)}
        className="flex w-full items-center gap-3 py-3 hover:bg-gray-3 dark:hover:bg-meta-4"
      >
        <div className="relative h-12 w-12 rounded-full">
          {productImage ? (
            <img
              src={productImage.thumbnail.source_url}
              className="rounded-full"
            />
          ) : (
            <ShopLogo className="rounded-full" />
          )}
          <span className="absolute right-0 bottom-0 h-5 w-5 rounded-full bg-white grid place-content-center">
            {isGood ? (
              <Icon icon={appIcons.check} className="text-meta-3 w-4.5 h-4.5" />
            ) : (
              <Icon icon={appIcons.bad} className="text-red-600 w-4 h-4" />
            )}
          </span>
        </div>

        <div className="flex flex-1 items-center justify-between">
          <div>
            <h5 className=" text-zinc-700 dark:text-white text-start font-medium text-sm">
              {product?.name}
            </h5>

            <p className="text-xs whitespace-nowrap text-zinc-500 dark:text-white text-ellipsis overflow-hidden max-w-[150px]">
              {comment}
            </p>
          </div>
          <div className="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full bg-yellow-500">
            <span className="text-xs font-bold text-white">+{rating}</span>
          </div>
        </div>
      </button>
    </>
  );
};

export default ReviewCard;
