interface TypesInferface {
  FETCH_REQUEST: string;
  FETCH_ERROR: string;
  CREATE_SUCCESS: string;
  READ_SUCCESS: string;
  UPDATE_SUCCESS: string;
  DELETE_SUCCESS: string;
}

const getTypes = (type: string) => {
  return {
    FETCH_REQUEST: `${type}_FETCH_REQUEST`,
    FETCH_ERROR: `${type}_FETCH_ERROR`,
    CREATE_SUCCESS: `${type}_CREATE_SUCCESS`,
    READ_SUCCESS: `${type}_READ_SUCCESS`,
    UPDATE_SUCCESS: `${type}_UPDATE_SUCCESS`,
    DELETE_SUCCESS: `${type}_DELETE_SUCCESS`,
  } as TypesInferface;
};

export interface ModelTypesInterface {
  TAG: TypesInferface;
  CATEGORY: TypesInferface;
  STORE: TypesInferface;
  BRAND: TypesInferface;
}

export const types: ModelTypesInterface = {
  TAG: getTypes('TAG'),
  CATEGORY: getTypes('CATEGORY'),
  STORE: getTypes('STORE'),
  BRAND: getTypes('BRAND'),
};
