import axiosClient from 'config/axiosClient';
import * as types from './categoryTypes';
import tokenHeader from 'config/tokenHeader';
import { CategoryInfo, CategoryResponse } from 'types/categoryTypes';
import { Dispatch } from '@reduxjs/toolkit';

import {
  SHOP_CREATE_CATEGORY,
  SHOP_DELETE_CATEGORY,
  SHOP_UPDATE_CATEGORY_SORT,
} from 'store/shops/shopTypes';
import toast from 'react-hot-toast';

const requestCategories = () => ({
  type: types.CATEGORY_FETCH_REQUEST,
});

export const setCategories = (category: CategoryResponse[]) => ({
  type: types.CATEGORY_FETCH_SUCCESS,
  payload: category,
});

const invalidCategories = () => ({
  type: types.CATEGORY_FETCH_FAIL,
});

export const getCategories: any =
  (shopId: string) => async (dispatch: Dispatch) => {
    dispatch(requestCategories());
    try {
      const res = await axiosClient.get(`/categories?shop=${shopId}`);
      dispatch({
        type: types.CATEGORY_FETCH_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      console.error(error);
      dispatch(invalidCategories());
    }
  };

export const createCategory =
  (category: any, shopId: string) => async (dispatch: Dispatch) => {
    dispatch(requestCategories());
    try {
      await tokenHeader();
      const res = await axiosClient.post(
        `/categories/shop/${shopId}`,
        category,
      );

      dispatch({
        type: types.CATEGORY_CREATE_SUCCESS,
        payload: res.data.doc,
      });

      dispatch({
        type: SHOP_CREATE_CATEGORY,
        payload: { shopId, categoryId: res.data.doc._id },
      });

      toast.success('Categoría creada exitósamente');
    } catch (error) {
      // console.log(error);
      dispatch(invalidCategories());
      toast.error('Ocurrió un error');
    }
  };

export const updateCategory =
  ({ data, categoryId, shopId }: any) =>
  async (dispatch: Dispatch) => {
    dispatch(requestCategories());
    try {
      await tokenHeader();
      const res = await axiosClient.put(
        `/categories/${categoryId}/shop/${shopId}`,
        data,
      );
      dispatch({
        type: types.CATEGORY_UPDATE_SUCCESS,
        payload: res.data.doc,
      });
      toast.success('Categoría actualizada exitósamente');
    } catch (error) {
      dispatch({
        type: types.CATEGORY_FETCH_FAIL,
      });
      toast.error('Hubo un error en la actualización');
    }
  };

export const updateCategorySort =
  (catSort: any, shopId: string) => async (dispatch: Dispatch) => {
    dispatch(requestCategories());
    try {
      await tokenHeader();
      dispatch({
        type: SHOP_UPDATE_CATEGORY_SORT,
        payload: { shopId, catSort },
      });
      const res = await axiosClient.post(`/categories/sort/shop/${shopId}`, {
        sort: catSort,
      });
      toast.success('Nuevo orden de categorías');
    } catch (error) {
      dispatch({
        type: types.CATEGORY_FETCH_FAIL,
      });
    }
  };

export const deleteCategory =
  (id: string, shopId: string) => async (dispatch: Dispatch) => {
    dispatch(requestCategories());
    try {
      await tokenHeader();
      await axiosClient.delete(`/categories/${id}/shop/${shopId}`);

      dispatch({
        type: types.CATEGORY_DELETE_SUCCESS,
        payload: id,
      });

      dispatch({
        type: SHOP_DELETE_CATEGORY,
        payload: { shopId, categoryId: id },
      });

      toast.success('Categoría Eliminada');
    } catch (error) {
      dispatch({
        type: types.CATEGORY_FETCH_FAIL,
      });
    }
  };

export const importStoreCategories =
  (fromStore: string, toStore: string) => async (dispatch: Dispatch) => {
    dispatch(requestCategories());
    try {
      await tokenHeader();
      const res = await axiosClient.post('/categories/import', {
        fromStore,
        toStore,
      });
      dispatch(setCategories(res.data.docs));
    } catch (error) {
      dispatch(invalidCategories());
    }
  };
