import SearchInput from 'components/SearchInput/SearchInput';

interface TableHeaderProps {
  title: string;
  onClick?: () => void;
  btnLabel?: string | null;
  searchText?: string;
  setSearch?: (value: string) => void;
  hideSearch?: boolean;
}

const TableHeader = ({
  title,
  onClick,
  btnLabel,
  searchText,
  setSearch,
  hideSearch,
}: TableHeaderProps) => {
  return (
    <>
      <section className="flex flex-col items-start gap-3 sm:flex-row justify-between sm:items-center w-full bg-white shadow-md py-3 px-5 rounded-md">
        <h3 className="text-black text-xl">{title}</h3>
        {btnLabel && (
          <button
            color={'secondary'}
            onClick={onClick}
            className="border-2 border-yellow-500 w-full sm:w-auto px-3 py-2 rounded-xl text-gray-600 font-medium"
          >
            {btnLabel}
          </button>
        )}
      </section>
      {!hideSearch && (
        <SearchInput searchText={searchText} setSearch={setSearch} />
      )}
    </>
  );
};

export default TableHeader;
