interface InputProps {
  searchText?: string;
  setSearch?: (value: string) => void;
}

const SearchInput = ({ searchText, setSearch }: InputProps) => {
  return (
    <section className="w-full sm:max-w-[15rem]">
      <input
        type={'search'}
        placeholder="Escribe lo que quieres buscar"
        onChange={(e) => setSearch?.(e.target.value)}
        value={searchText}
        className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
      />
    </section>
  );
};

export default SearchInput;
