/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import CustomField from 'components/CustomField/CustomField';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import LoadingFullScreen from 'components/LoadingFullScreen/LoadingFullScreen';
import categoryValidation from './categoryValidation';
import { getSelectOptions } from 'helpers/objectHelpers';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import MediaSelector from 'components/MediaSelector/MediaSelector';

interface FormProps {
  onSubmit: (values: any, actions: FormikHelpers<any>) => void;
  doc?: any;
}

const CategoryForm = ({ onSubmit, doc }: FormProps) => {
  const state = useSelector((state: RootState) => state);
  const loading = state.categories.loading;
  const categories = state.categories.docs;

  const initialValues = {
    name: doc?.name || '',
    description: doc?.description || '',
    parent: doc?.parent ?? '',
    image: doc?.image?._id ?? null,
  };

  const handleSubmit = (data: any, actions: FormikHelpers<any>) => {
    data.shop = state.auth.currentShop;
    onSubmit?.(data, actions);
  };

  const categoryOptions = getSelectOptions(categories);

  return (
    <section>
      {loading && <LoadingFullScreen />}
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={categoryValidation}
      >
        {(formikProps: FormikValues) => {
          const { errors, touched, values, handleChange, setFieldValue } =
            formikProps;
          return (
            <Form className="grid gap-4">
              <CustomField
                type="text"
                name={'name'}
                label="Nombre"
                value={values.name}
                onChange={handleChange}
                placeholder='Ej. "Chicken Burguer'
                error={errors.name}
                touched={touched.name}
                required
              />
              <MediaSelector
                name="image"
                setFieldValue={setFieldValue}
                defaultMedium={values.image}
              />
              <CustomSelect
                options={[...categoryOptions]}
                name={'parent'}
                label="Categoría Padre"
                value={values.parent}
                error={errors.parent}
                touched={touched.parent}
                onChange={setFieldValue}
                placeholder={'Ej. "Bebidas"'}
                required
              />
              {/* <ErrorMessage name="name" /> */}
              <button
                type="submit"
                color={'secondary'}
                className="bg-yellow-500 text-primary-50 p-2 rounded-xl font-medium"
              >
                Guardar
              </button>
            </Form>
          );
        }}
      </Formik>
    </section>
  );
};

export default CategoryForm;
