import Burguer1 from './Burguer1';
import Burguer2 from './Burguer2';
import './burguerButton.css';

interface BurguerButtonProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const BurguerButton = ({ open, setOpen }: BurguerButtonProps) => {
  return (
    <button
      className={`burguer-button__button text-yellow-500 ${open ? 'open' : ''}`}
      onClick={() => setOpen(!open)}
    >
      <section>
        <div className="burguer-button__line1">
          <Burguer1 />
        </div>
        <div className="burguer-button__line2 mt-2">
          <Burguer2 />
        </div>
      </section>
    </button>
  );
};

export default BurguerButton;
