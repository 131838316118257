import FieldWrapper from 'components/CustomField/FieldWrapper';
import { Field } from 'formik';
import SelectDropdown from './SelectDropdown';
import { useRef, useState } from 'react';
import useOnClickOutside from 'hooks/useClickOutside';
import { SelectOption } from 'types/globalTypes';
import SelectedOption from './SelectedOption';

interface CustomSelectProps {
  options: SelectOption[];
  name: string;
  value: string;
  placeholder?: string;
  label?: string;
  error: string;
  touched: boolean;
  required?: boolean;
  onChange: (name: string, value: any) => void;
}

const CustomSelect = ({
  options,
  name,
  placeholder,
  label,
  value,
  error,
  touched,
  required = false,
  onChange,
}: CustomSelectProps) => {
  const [open, setOpen] = useState(false);
  const containerRef = useRef(null);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  useOnClickOutside(containerRef, handleClose);
  const isError = error && touched;
  const openClass = open ? 'rounded-b-none border-b-[0.5px]' : '';
  const errorClass = isError
    ? 'border-red-500'
    : touched
    ? 'border-green-700'
    : '';
  const className = `border-2 border-gray-300 p-2 rounded-xl w-full ${errorClass}`;
  const handleChange = (e: any) => {
    const value = e.target.value;
    onChange?.(name, value);
  };

  const onClick = (selected: SelectOption) => {
    onChange?.(name, selected.value);
  };

  const filteredOptions = options.filter((option) =>
    option?.label?.toLowerCase?.()?.includes?.(value?.toLowerCase?.()),
  );

  const selectedOption = options?.find((option) => option.value === value);
  const selectedLabel = selectedOption?.label || '';

  const clearOption = () => {
    onChange?.(name, '');
  };

  return (
    <>
      <FieldWrapper label={label} error={error} required={required}>
        <section className="relative inline-block w-full" ref={containerRef}>
          {selectedOption ? (
            <SelectedOption
              className={className}
              label={selectedLabel}
              onClick={clearOption}
            />
          ) : (
            <>
              <Field
                name={name}
                value={value}
                type={'text'}
                onChange={handleChange}
                placeholder={placeholder}
                className={`${className} ${openClass}`}
                onFocus={handleOpen}
              />
              <SelectDropdown
                open={open}
                wrapClassName={errorClass}
                options={filteredOptions}
                onChange={onClick}
              />
            </>
          )}
        </section>
      </FieldWrapper>
    </>
  );
};

export default CustomSelect;
