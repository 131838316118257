const LoadingFullScreen = () => {
  return (
    <section className="h-screen w-screen bg-yellow-50 bg-opacity-50 text-yellow-500 fixed left-0 top-0 flex flex-col justify-center items-center z-50">
      <svg
        width="119"
        height="119"
        viewBox="0 0 119 119"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M65.1235 72.5078C65.1235 75.5859 62.6282 78.0812 59.5501 78.0812C56.472 78.0812 53.9767 75.5859 53.9767 72.5078C53.9767 69.4296 56.472 66.9343 59.5501 66.9343C62.6282 66.9343 65.1235 69.4296 65.1235 72.5078ZM61.4079 72.5078C61.4079 73.5338 60.5761 74.3656 59.5501 74.3656C58.5241 74.3656 57.6923 73.5338 57.6923 72.5078C57.6923 71.4817 58.5241 70.6499 59.5501 70.6499C60.5761 70.6499 61.4079 71.4817 61.4079 72.5078Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.1189 83.6546C52.1189 86.7327 49.6236 89.228 46.5454 89.228C43.4673 89.228 40.972 86.7327 40.972 83.6546C40.972 80.5765 43.4673 78.0812 46.5454 78.0812C49.6236 78.0812 52.1189 80.5765 52.1189 83.6546ZM48.4032 83.6546C48.4032 84.6806 47.5715 85.5124 46.5454 85.5124C45.5194 85.5124 44.6876 84.6806 44.6876 83.6546C44.6876 82.6286 45.5194 81.7968 46.5454 81.7968C47.5715 81.7968 48.4032 82.6286 48.4032 83.6546Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M59.5501 100.375C62.6282 100.375 65.1235 97.8796 65.1235 94.8015C65.1235 91.7233 62.6282 89.228 59.5501 89.228C56.472 89.228 53.9767 91.7233 53.9767 94.8015C53.9767 97.8796 56.472 100.375 59.5501 100.375ZM61.4079 94.8015C61.4079 95.8275 60.5761 96.6593 59.5501 96.6593C58.5241 96.6593 57.6923 95.8275 57.6923 94.8015C57.6923 93.7754 58.5241 92.9437 59.5501 92.9437C60.5761 92.9437 61.4079 93.7754 61.4079 94.8015Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M78.1282 83.6546C78.1282 86.7327 75.6329 89.228 72.5548 89.228C69.4766 89.228 66.9813 86.7327 66.9813 83.6546C66.9813 80.5765 69.4766 78.0812 72.5548 78.0812C75.6329 78.0812 78.1282 80.5765 78.1282 83.6546ZM74.4126 83.6546C74.4126 84.6806 73.5808 85.5124 72.5548 85.5124C71.5287 85.5124 70.697 84.6806 70.697 83.6546C70.697 82.6286 71.5287 81.7968 72.5548 81.7968C73.5808 81.7968 74.4126 82.6286 74.4126 83.6546Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.5408 13.0579C32.5147 13.0579 31.683 13.8896 31.683 14.9157C31.683 15.9417 32.5147 16.7735 33.5408 16.7735H48.4032C51.4814 16.7735 53.9767 19.2688 53.9767 22.3469V35.3516H33.5408C30.4627 35.3516 27.9673 37.8469 27.9673 40.925V44.6406C27.9673 47.0865 29.5429 49.1644 31.7344 49.9148L33.5639 104.278C33.665 107.281 36.129 109.664 39.1342 109.664H79.966C82.9712 109.664 85.4352 107.281 85.5363 104.278L87.4172 48.3875V40.925C87.4172 37.8469 84.9219 35.3516 81.8438 35.3516H57.6923V22.3469C57.6923 17.2167 53.5334 13.0579 48.4032 13.0579H33.5408ZM33.5408 39.0672H81.8438C82.8699 39.0672 83.7016 39.899 83.7016 40.925L83.7016 48.325L83.4711 55.1763C82.9152 55.0644 82.2895 54.9579 81.6098 54.8729C78.9639 54.5422 75.3627 54.5212 71.8648 55.9203C66.115 58.2203 60.4164 58.2203 54.6666 55.9203C48.9236 53.6232 41.7507 53.3141 35.623 54.9933L35.4622 50.214H72.5548C73.5808 50.214 74.4126 49.3823 74.4126 48.3562C74.4126 47.3302 73.5808 46.4984 72.5548 46.4984H33.5408C32.5147 46.4984 31.683 45.6667 31.683 44.6406V40.925C31.683 39.899 32.5147 39.0672 33.5408 39.0672ZM81.1489 58.5598C81.9935 58.6654 82.7404 58.8101 83.3441 58.9499L81.8228 104.153C81.7891 105.154 80.9678 105.948 79.966 105.948H39.1342C38.1325 105.948 37.3111 105.154 37.2774 104.153L35.752 58.8278C41.2669 57.0873 48.0314 57.2681 53.2867 59.3702C59.9223 62.0245 66.6091 62.0245 73.2447 59.3702C75.9396 58.2923 78.8406 58.2713 81.1489 58.5598Z"
          fill="currentColor"
        />
      </svg>
      <div className="flex justify-center items-center">
        <div
          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-b-transparent border-yellow-500"
          role="status"
        >
          <span className=""></span>
        </div>
      </div>
    </section>
  );
};

export default LoadingFullScreen;
