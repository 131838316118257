import { UilCheck } from '@iconscout/react-unicons';
import Carousel from 'components/Carousel/Carousel';
import Navbar from 'components/SiteNavbar/Navbar';
import imgHome1 from 'assets/images/home1.jpg';
import imgHome2 from 'assets/images/home2.jpg';
import imgHome3 from 'assets/images/home3.jpg';
import imgHome4 from 'assets/images/home4.jpg';
import imgHome5 from 'assets/images/home5.jpg';
import { handleGetMenew } from 'helpers/whatsappHandlers';

const HomePage = () => {
  const images = [
    {
      src: imgHome1,
      alt: 'Image 1',
    },
    {
      src: imgHome2,
      alt: 'Image 1',
    },
    {
      src: imgHome3,
      alt: 'Image 1',
    },
    {
      src: imgHome4,
      alt: 'Image 1',
    },
    {
      src: imgHome5,
      alt: 'Image 1',
    },
  ];

  return (
    <section>
      <Navbar />
      <section className="w-full h-screen bg-primary-100">
        <section className="md:grid md:grid-cols-2 h-full flex flex-col mx-auto px-6">
          <section className="w-full py-2 grid md:place-content-center">
            <section className="mt-4 md:mr-5">
              <h1 className="w-full sm:max-w-lg text-yellow-500 pr-5 mb-2  text-5xl leading-9 sm:text-8xl sm:leading-[3.5rem] dongle mt-5">
                Menew
              </h1>
              <p className="leading-4 mt-1 text-yellow-500 max-w-[400px] text-lg">
                Menú digital, sistema de recompensas
              </p>
              <ul className="w-full text-secondary-200 text-sm lg:text-lg mt-3 lg:mt-4 font-sans">
                <li className="flex gap-2">
                  <UilCheck className="text-secondary-100" />
                  Ahorra dinero
                </li>
                <li className="flex gap-2">
                  <UilCheck className="text-secondary-100" />
                  Aumenta tus ventas
                </li>
                <li className="flex gap-2">
                  <UilCheck className="text-secondary-100" />
                  Mejora la experiencia de tus clientes
                </li>
              </ul>
              <button
                onClick={handleGetMenew}
                className="bg-yellow-500 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-500 text-primary-100 px-4 py-2 rounded-full mt-4 text-1xl lg:text-2xl leading-6"
              >
                Obtener Menew Ahora
              </button>
            </section>
          </section>
          <section className="pt-4 lg:pt-16 w-full h-full flex items-end">
            <section className="w-full h-full lg:h-[93%] rounded-2xl overflow-hidden">
              <Carousel images={images} />
            </section>
          </section>
        </section>
      </section>
    </section>
  );
};

export default HomePage;
