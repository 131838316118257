import 'assets/css/App.css';
import RouterApp from 'routing/RouterApp';
import { Provider } from 'react-redux';
import store, { persistor } from 'store';
import AppState from 'context/appContext/appState';
import { PersistGate } from 'redux-persist/integration/react';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppState>
          <section className="App">
            <Toaster />
            <RouterApp />
          </section>
        </AppState>
      </PersistGate>
    </Provider>
  );
}

export default App;
