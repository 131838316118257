import axiosClient from './axiosClient';

const tokenHeader = async () => {
  const token = localStorage.getItem('token');

  if (token) {
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axiosClient.defaults.headers.common['Authorization'];
  }
};

export default tokenHeader;
