/* eslint-disable @typescript-eslint/no-unused-vars */

interface CheckBoxProps {
  value: string;
  onChange: (value: string) => void;
  isChecked: boolean;
}

const CheckboxFive = ({ value, onChange, isChecked }: CheckBoxProps) => {
  const handleChange = () => {
    onChange(value);
  };

  return (
    <div
      className={`bg-gray-50 p-3 px-5 rounded-xl ${
        isChecked
          ? 'bg-teal-50 border border-teal-600 bg-opacity-30 text-teal-700'
          : 'bg-gray-50'
      }`}
    >
      <label
        htmlFor={value}
        className="flex cursor-pointer select-none items-center"
      >
        <div className="relative">
          <input
            type="checkbox"
            id={value}
            className="sr-only"
            onChange={handleChange}
          />
          <div
            className={`box mr-4 flex h-5 w-5 items-center justify-center rounded-full border border-teal-600  ${
              isChecked && '!border-4 bg-white'
            }`}
          >
            <span className="h-2.5 w-2.5 rounded-full bg-white dark:bg-transparent"></span>
          </div>
        </div>
        {value}
      </label>
    </div>
  );
};

export default CheckboxFive;
