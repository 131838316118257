import ShopForm from './ShopForm/ShopForm';
import { useDispatch, useSelector } from 'react-redux';
import { updateShop } from 'store/shops/shopActions';
import { RootState } from 'store';

const ShopSettingsPage = () => {
  const { auth, shops } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const shop = shops.docs.find((shop: any) => shop._id === auth.currentShop);

  const handleSubmit = async (data: any) => {
    data._id = shop._id;
    await dispatch(updateShop(data));
  };

  return (
    <section className="px-6 pt-8 max-w-2xl mx-auto bg-white">
      <ShopForm onSubmit={handleSubmit} doc={shop} isUpdate />
    </section>
  );
};

export default ShopSettingsPage;
