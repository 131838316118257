import { SelectOption } from 'types/globalTypes';
import CustomSelectRadio from 'components/CustomSelectRadio/CustomSelectRadio';
import useGetTags from 'services/tagServices';

interface CustomSelectTagsProps {
  type?: string;
  name: string;
  value: string;
  label?: string;
  error: string;
  touched: boolean;
  required?: boolean;
  to?: string;
}

const CustomSelectTags = (props: CustomSelectTagsProps) => {
  const { to = 'product' } = props;
  const { tags, isLoading } = useGetTags();
  const productOptions: any = [];
  tags?.forEach?.((tag: any) => {
    if (tag.to.includes(to)) {
      productOptions.push({
        label: tag.name,
        value: tag._id,
      });
    }
  });

  return (
    <>
      <CustomSelectRadio
        {...props}
        label="Etiquetas"
        type="checkbox"
        options={productOptions}
        isLoading={isLoading}
      />
    </>
  );
};

export default CustomSelectTags;
