import FieldWrapper from 'components/CustomField/FieldWrapper';
import { Field } from 'formik';
import { useState } from 'react';
import { SelectOption } from 'types/globalTypes';

interface CustomSelectRadioProps {
  type?: string;
  options: SelectOption[];
  name: string;
  label?: string;
  value?: any;
  error: string;
  touched: boolean;
  required?: boolean;
  isLoading?: boolean;
}

const CustomSelectRadio = ({
  type = 'radio',
  options,
  name,
  label,
  error,
  required = false,
  value,
  isLoading = false,
}: CustomSelectRadioProps) => {
  const [open, setOpen] = useState(false);
  const selectLabels = value?.map?.(
    (v: any) => options.find((opt) => opt.value === v)?.label,
  );
  return (
    <>
      <FieldWrapper label={label} error={error} required={required}>
        <section className="relative w-full">
          {isLoading && <span>Cargando etiquetas...</span>}
          <button
            onClick={() => setOpen(!open)}
            type="button"
            className="bg-white w-full rounded-lg border-2 flex justify-between p-2 items-center text-left"
          >
            <span className="text-zinc-400 block max-w-[280px] whitespace-nowrap text-eliipsis overflow-hidden">
              Seleccionado{' '}
              <span className="font-bold">({selectLabels?.length ?? 0})</span>{' '}
            </span>

            <section className="ml-2">Editar</section>
          </button>

          <section
            className={`absolute bg-white top-12 w-full p-2 rounded-md border border-zinc-200 shadow-lg ${
              open ? 'block' : 'hidden'
            }`}
          >
            <section className="max-h-48 overflow-y-auto w-full  flex flex-col gap-2">
              {options?.map?.((option) => (
                <label
                  key={option.value}
                  className="flex gap-2 items-center border rounded-md border-zinc-200 p-1"
                >
                  <Field type={type} name={name} value={option.value} />
                  {option.label}
                </label>
              ))}
            </section>
            <button
              type="button"
              className="text-yellow-500 mt-2 p-2 rounded-md w-full font-bold"
              onClick={() => setOpen(false)}
            >
              Listo
            </button>
          </section>
        </section>
      </FieldWrapper>
    </>
  );
};

export default CustomSelectRadio;
