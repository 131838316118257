import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

export const useMediaStore = create(
  persist(
    (set, get: any) => ({
      media: [] as any,
      addMedia: (item: any) => set({ media: [item, ...get().media] }),
      removeMedia: (_id: any) =>
        set({ media: get().media.filter((item: any) => item._id !== _id) }),
    }),
    {
      name: 'media-storage',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

export const setMedia = (media: any) => {
  useMediaStore.setState(() => ({
    media,
  }));
};
