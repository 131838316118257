import CustomField from 'components/CustomField/CustomField';
import LoadingFullScreen from 'components/LoadingFullScreen/LoadingFullScreen';
import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authLogin } from 'store/auth/authActions';
import { loginValidation } from './loginValidation';
import { resetState } from 'store/actions/globalActions';
import CustomFieldPassword from 'components/CustomField/CustomFieldPassword';

const LoginForm = () => {
  const { isAuth, loading, checkLoading } = useSelector(
    (state: any) => state.auth,
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuth) {
      navigate('/dashboard');
    }
  }, [isAuth, navigate]);

  const handleSubmit = (data: any) => {
    dispatch(resetState());
    dispatch(authLogin(data));
  };

  return (
    <>
      {!checkLoading && (
        <section className="w-full flex justify-center items-center gap-2">
          {loading && <LoadingFullScreen />}
          <section className="w-full">
            <Formik
              onSubmit={handleSubmit}
              initialValues={{ email: '', password: '' }}
              validationSchema={loginValidation}
            >
              {({ values, errors, touched, handleChange }: any) => (
                <Form className="grid gap-3">
                  <CustomField
                    type="email"
                    name={'email'}
                    label="Correo electrónico"
                    value={values.email}
                    onChange={handleChange}
                    required
                    placeholder="Ej. micorreo@gmail.com"
                    error={errors.email}
                    touched={touched.email}
                  />
                  <CustomFieldPassword
                    type="password"
                    name={'password'}
                    label="Contraseña"
                    value={values.password}
                    onChange={handleChange}
                    required
                    placeholder="Contraseña"
                    error={errors.password}
                    touched={touched.password}
                  />
                  <button
                    type="submit"
                    className="border-2 p-2 rounded-2xl mt-3 w-full font-semibold bg-yellow-500 text-white hover:opacity-90 mb-6 transition duration-300 ease-in-out"
                  >
                    Ingresar
                  </button>
                </Form>
              )}
            </Formik>
            <hr className="mb-6 border-t"></hr>
            {/* <div className="text-center">
              <Link
                className="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"
                to={'/sign-up'}
              >
                Create an Account!
              </Link>
            </div> */}
            {/* <div className="text-center">
              <a
                className="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"
                href="#"
              >
                Forgot Password?
              </a>
            </div> */}
          </section>
        </section>
      )}
    </>
  );
};

export default LoginForm;
