import { Link } from 'react-router-dom';
import Logo from './Logo';

const LinkLogo = () => {
  return (
    <Link to="/" rel="noreferrer">
      <Logo />
    </Link>
  );
};

export default LinkLogo;
