import * as types from './productTypes';

const initialState = {
  docs: [] as any,
  docsByShop: {} as any,
  loading: false,
  error: null,
};

const getDeleteProducts = ({ state, action }: any) => {
  const docs = state.docsByShop[action.payload.shop];
  const deleteProducts = docs.filter(
    (product: any) => product._id !== action.payload._id,
  );

  return deleteProducts;
};

const getUpdateProducts = ({ state, action }: any) => {
  const docs = state.docsByShop[action.payload.shop];
  const updateProducts = docs.map((product: any) =>
    product._id === action.payload._id ? action.payload : product,
  );

  return updateProducts;
};

const getCreateProducts = ({ state, action }: any) => {
  const docs = state.docsByShop?.[action.payload.shop] ?? [];
  const createProducts = [action.payload, ...docs];
  return createProducts;
};

const productReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.PRODUCTS_FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.PRODUCTS_FETCH_SUCCESS:
      return {
        ...state,
        docs: action.payload.docs,
        docsByShop: {
          ...state.docsByShop,
          [action.payload.shop]: action.payload.docs,
        },
        loading: false,
      };
    case types.PRODUCTS_FETCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.PRODUCT_CREATE_SUCCESS:
      return {
        ...state,
        docs: [action.payload, ...state.docs],
        docsByShop: {
          ...state?.docsByShop,
          [action.payload.shop]: getCreateProducts({ state, action }),
        },
        loading: false,
      };
    case types.PRODUCT_UPDATE_SUCCESS:
      return {
        ...state,
        docs: state.docs.map((product: any) =>
          product._id === action.payload._id ? action.payload : product,
        ),
        docsByShop: {
          ...state.docsByShop,
          [action.payload.shop]: getUpdateProducts({ state, action }),
        },
        loading: false,
      };
    case types.PRODUCT_DELETE_SUCCESS:
      return {
        ...state,
        docs: state.docs.filter(
          (product: any) => product._id !== action.payload,
        ),
        docsByShop: {
          ...state.docsByShop,
          [action.payload.shop]: getDeleteProducts({ state, action }),
        },
        loading: false,
      };
    case types.PRODUCTS_CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};

export default productReducer;
