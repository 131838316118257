import SearchInput from 'components/SearchInput/SearchInput';
import { useState } from 'react';
import ValidateReviewScanner from '../ValidateReviewScanner';

interface DashboardHeaderProps {
  title: string;
  onClick?: () => void;
  btnLabel?: string | null;
  searchText?: string;
  setSearch?: (value: string) => void;
  hideSearch?: boolean;
}

const DashboardHeader = ({
  title,
  onClick,
  btnLabel,
  searchText,
  setSearch,
  hideSearch,
}: DashboardHeaderProps) => {
  const [openValidate, setOpenValidate] = useState(false);
  return (
    <>
      <section className="flex flex-col items-start gap-3 sm:flex-row justify-between sm:items-center w-full bg-white shadow-md py-3 px-5 rounded-md">
        <h3 className="text-black text-xl">{title}</h3>
        <section className="flex gap-2 w-full sm:w-auto">
          {btnLabel && (
            <button
              onClick={onClick}
              className="border-2 border-yellow-500 w-full sm:w-auto px-3 py-2 rounded-xl text-gray-600 font-medium"
            >
              {btnLabel}
            </button>
          )}

          <button
            onClick={() => setOpenValidate(true)}
            className="border-2 border-yellow-500 w-full sm:w-auto px-3 py-2 rounded-xl text-gray-600 font-medium"
          >
            Validar Reseña
          </button>
        </section>
      </section>
      <ValidateReviewScanner open={openValidate} setOpen={setOpenValidate} />
      {!hideSearch && (
        <SearchInput searchText={searchText} setSearch={setSearch} />
      )}
    </>
  );
};

export default DashboardHeader;
