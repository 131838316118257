import React from 'react';

type type = 'itemTitle' | 'description' | 'price';

interface TextProps {
  type?: type;
  className?: string;
  children: React.ReactNode;
}

const Text = ({ type = 'itemTitle', className, children }: TextProps) => {
  const styles = {
    itemTitle: 'text-base',
    description: 'text-sm',
    price: 'font-bold text-base text-yellow-500',
  };

  const styleClass = styles[type];
  const isDescription = type === 'description';
  const customTag = isDescription ? 'p' : 'span';

  return React.createElement(
    customTag,
    { className: `${styleClass} ${className}` },
    children,
  );
};

export default Text;
