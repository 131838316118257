/* eslint-disable no-case-declarations */
import { arrayMongoDocsToObject } from 'helpers/objectHelpers';

const getDocReducer = (types: any) => {
  const initialState = {
    docs: [],
    loading: false,
    error: null,
    docsById: {},
  };
  const docReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case types.FETCH_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case types.FETCH_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case types.READ_SUCCESS:
        return {
          ...state,
          docs: action.payload,
          docsById: arrayMongoDocsToObject(action.payload),
          loading: false,
        };
      case types.CREATE_SUCCESS:
        const data = [action.payload, ...state.docs];
        return {
          ...state,
          docs: data,
          docsById: arrayMongoDocsToObject(data),
          loading: false,
        };
      case types.UPDATE_SUCCESS:
        const dataUpdate = state.docs.map((doc: any) =>
          doc._id === action.payload._id ? action.payload : doc,
        );
        return {
          ...state,
          docs: dataUpdate,
          docsById: arrayMongoDocsToObject(dataUpdate),
          loading: false,
        };
      case types.DELETE_SUCCESS:
        const dataDelete = state.docs.filter(
          (doc: any) => doc._id !== action.payload,
        );
        return {
          ...state,
          docs: dataDelete,
          docsById: arrayMongoDocsToObject(dataDelete),
          loading: false,
        };
      default:
        return state;
    }
  };
  return docReducer;
};

export default getDocReducer;
