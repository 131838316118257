export const SHOPS_FETCH_START = 'SHOPS_FETCH_START';
export const SHOPS_FETCH_SUCCESS = 'SHOPS_FETCH_SUCCESS';
export const SHOPS_FETCH_FAIL = 'SHOPS_FETCH_FAIL';
export const SHOP_CREATE_SUCCESS = 'SHOP_CREATE_SUCCESS';
export const SHOP_UPDATE_SUCCESS = 'SHOP_UPDATE_SUCCESS';
export const SHOP_DELETE_SUCCESS = 'SHOP_DELETE_SUCCESS';
export const SHOPS_CLEAR_STATE = 'SHOPS_CLEAR_STATE';
export const SHOP_CREATE_CATEGORY = 'SHOP_CREATE_CATEGORY';
export const SHOP_DELETE_CATEGORY = 'SHOP_DELETE_CATEGORY';
export const SHOP_UPDATE_CATEGORY_SORT = 'SHOP_UPDATE_CATEGORY_SORT';
