import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

export const useReviews = create(
  persist(
    () => ({
      reviewsByShop: {},
    }),
    {
      name: 'reviews-storage',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

export const setReviews = (reviews: any, shopId: any) => {
  useReviews.setState((state) => ({
    reviewsByShop: { ...state.reviewsByShop, [shopId]: reviews },
  }));
};
