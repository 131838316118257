const Logo = () => {
  return (
    <svg
      width="100%"
      height="auto"
      viewBox="0 0 938 186"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M200.344 0V183.632H163.487V64.1924L114.257 183.632H86.3508L36.857 64.1924V183.632H0V0H41.8591L100.304 136.54L158.749 0H200.344Z"
        fill="#D2A735"
      />
      <path
        d="M372.365 107.601C372.365 112.863 372.014 117.598 371.312 121.808H264.69C265.567 132.331 269.253 140.574 275.747 146.537C282.241 152.501 290.227 155.482 299.704 155.482C313.394 155.482 323.135 149.607 328.926 137.856H368.679C364.467 151.887 356.394 163.463 344.459 172.583C332.524 181.528 317.869 186 300.494 186C286.453 186 273.816 182.931 262.584 176.792C251.527 170.478 242.839 161.621 236.521 150.221C230.378 138.82 227.306 125.666 227.306 110.758C227.306 95.6747 230.378 82.4328 236.521 71.0325C242.663 59.6323 251.263 50.8628 262.32 44.7242C273.378 38.5856 286.102 35.5163 300.494 35.5163C314.359 35.5163 326.733 38.4979 337.614 44.4611C348.671 50.4243 357.183 58.9307 363.151 69.9802C369.294 80.8543 372.365 93.3946 372.365 107.601ZM334.192 97.0778C334.016 87.6068 330.594 80.0651 323.924 74.4526C317.255 68.6648 309.094 65.7709 299.441 65.7709C290.314 65.7709 282.592 68.5771 276.273 74.1895C270.131 79.6266 266.357 87.256 264.953 97.0778H334.192Z"
        fill="#D2A735"
      />
      <path
        d="M480.09 35.7793C497.465 35.7793 511.506 41.3041 522.212 52.3536C532.918 63.2277 538.271 78.4866 538.271 98.1301V183.632H501.414V103.129C501.414 91.553 498.518 82.6959 492.726 76.5573C486.934 70.2433 479.037 67.0863 469.032 67.0863C458.853 67.0863 450.78 70.2433 444.812 76.5573C439.02 82.6959 436.124 91.553 436.124 103.129V183.632H399.267V37.884H436.124V56.0368C441.039 49.7228 447.269 44.8119 454.816 41.3041C462.539 37.6209 470.963 35.7793 480.09 35.7793Z"
        fill="#D2A735"
      />
      <path
        d="M708.901 107.601C708.901 112.863 708.55 117.598 707.848 121.808H601.226C602.104 132.331 605.79 140.574 612.283 146.537C618.777 152.501 626.763 155.482 636.24 155.482C649.93 155.482 659.671 149.607 665.463 137.856H705.216C701.003 151.887 692.93 163.463 680.995 172.583C669.061 181.528 654.406 186 637.03 186C622.989 186 610.353 182.931 599.12 176.792C588.063 170.478 579.375 161.621 573.057 150.221C566.914 138.82 563.843 125.666 563.843 110.758C563.843 95.6747 566.914 82.4328 573.057 71.0325C579.2 59.6323 587.8 50.8628 598.857 44.7242C609.914 38.5856 622.638 35.5163 637.03 35.5163C650.895 35.5163 663.269 38.4979 674.15 44.4611C685.208 50.4243 693.72 58.9307 699.687 69.9802C705.83 80.8543 708.901 93.3946 708.901 107.601ZM670.728 97.0778C670.553 87.6068 667.13 80.0651 660.461 74.4526C653.791 68.6648 645.63 65.7709 635.977 65.7709C626.851 65.7709 619.128 68.5771 612.81 74.1895C606.667 79.6266 602.894 87.256 601.489 97.0778H670.728Z"
        fill="#D2A735"
      />
      <path
        d="M937.991 37.884L895.342 183.632H855.589L828.999 81.819L802.41 183.632H762.393L719.481 37.884H756.865L782.665 148.905L810.571 37.884H849.534L876.914 148.642L902.714 37.884H937.991Z"
        fill="#D2A735"
      />
      <path
        d="M480.099 35.7792C497.474 35.7792 511.515 41.304 522.221 52.3535C532.927 63.2276 538.28 78.4865 538.28 98.13V183.632H501.423V103.129C501.423 91.5529 498.527 82.6958 492.735 76.5572C486.944 70.2432 479.046 67.0862 469.042 67.0862C458.862 67.0862 450.789 70.2432 444.821 76.5572C439.029 82.6958 436.134 91.5529 436.134 103.129V183.632H399.276V37.8839H436.134V56.0367C441.048 49.7227 447.278 44.8118 454.825 41.304C462.548 37.6208 470.972 35.7792 480.099 35.7792Z"
        fill="#525D32"
      />
      <path
        d="M708.911 107.601C708.911 112.863 708.56 117.598 707.857 121.808H601.235C602.113 132.331 605.799 140.574 612.292 146.537C618.786 152.501 626.772 155.482 636.25 155.482C649.939 155.482 659.68 149.607 665.472 137.856H705.225C701.013 151.887 692.939 163.462 681.005 172.583C669.07 181.527 654.415 186 637.039 186C622.999 186 610.362 182.931 599.129 176.792C588.072 170.478 579.384 161.621 573.066 150.221C566.923 138.82 563.852 125.666 563.852 110.758C563.852 95.6746 566.923 82.4327 573.066 71.0324C579.209 59.6322 587.809 50.8627 598.866 44.7241C609.923 38.5855 622.648 35.5162 637.039 35.5162C650.905 35.5162 663.278 38.4978 674.16 44.461C685.217 50.4242 693.729 58.9306 699.696 69.9801C705.839 80.8542 708.911 93.3945 708.911 107.601ZM670.737 97.0777C670.562 87.6067 667.139 80.065 660.47 74.4525C653.8 68.6647 645.639 65.7708 635.986 65.7708C626.86 65.7708 619.137 68.577 612.819 74.1894C606.676 79.6265 602.903 87.2559 601.499 97.0777H670.737Z"
        fill="#525D32"
      />
      <path
        d="M938 37.8839L895.351 183.632H855.598L829.008 81.8189L802.419 183.632H762.403L719.49 37.8839H756.874L782.674 148.905L810.58 37.8839H849.543L876.923 148.642L902.723 37.8839H938Z"
        fill="#525D32"
      />
    </svg>
  );
};

export default Logo;
