import * as Yup from 'yup';

const shopValidation = Yup.object().shape({
  name: Yup.string().required('Nombre requerido'),
  paymentMethods: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Nombre es requerido'),
      description: Yup.string().required('Descripción requerida'),
    }),
  ),
});

export default shopValidation;
