import * as types from './authTypes';

const initialState = {
  loading: false,
  isAuth: false,
  loginError: null,
  user: null,
  checkLoading: false,
  currentShop: null,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.CHECK_SESSION_START:
      return {
        ...state,
        checkLoading: true,
      };
    case types.LOGIN_START:
      return {
        ...state,
        loading: true,
        isAuth: false,
        user: null,
        loginError: null,
        checkLoading: false,
        currentShop: null,
      };
    case types.LOGIN_SUCCESS:
      localStorage.setItem('token', action.payload.token);
      return {
        ...state,
        isAuth: true,
        loginError: null,
        loading: false,
        checkLoading: false,
        user: action.payload.user,
        currentShop: action.payload.currentShop,
      };
    case types.LOGIN_ERROR:
      localStorage.removeItem('token');
      return {
        ...state,
        isAuth: false,
        user: null,
        loginError: action.payload,
        checkLoading: false,
        loading: false,
        currentShop: null,
      };
    case types.SET_CURRENT_SHOP:
      return {
        ...state,
        currentShop: action.payload,
      };
    case types.LOGOUT_SUCCESS:
      localStorage.removeItem('token');
      return initialState;
    default:
      return state;
  }
};
