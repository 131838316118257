interface ContainerCenterProps {
  children: React.ReactNode;
}

const ContainerCenter = ({ children }: ContainerCenterProps) => (
  <section className="bg-white rounded-md shadow-sm flex felx-col justify-center w-full ">
    <section className="w-full">{children}</section>
  </section>
);

export default ContainerCenter;
