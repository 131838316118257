import { ChildrenType } from 'types/globalTypes';

const CardItem = ({ children }: ChildrenType) => {
  return (
    <section className="border border-gray-50 shadow-sm p-2 rounded-lg">
      {children}
    </section>
  );
};

export default CardItem;
