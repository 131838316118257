import { ApexOptions } from 'apexcharts';
import useCurrentShop from 'hooks/useCurrentShop';
import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

interface ChartBarState {
  series: { data: number[] }[];
}

const ChartBar: React.FC = () => {
  const shop = useCurrentShop();
  const qualifiers = shop?.qualifiers;
  const [state, setState] = useState<ChartBarState>({
    series: [
      {
        data: [5, 4.5, 4, 3, 4.5],
      },
    ],
  });

  const options: ApexOptions = {
    colors: ['#3C50E0'],
    chart: {
      fontFamily: 'Satoshi, sans-serif',
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        // endingShape: "rounded",
        borderRadius: 2,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 4,
      colors: ['transparent'],
    },
    xaxis: {
      categories: [
        'Comida',
        'Ambiente',
        'Mesonero',
        'Tiempo',
        'Calidad/Precio',
      ],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'left',
      fontFamily: 'inter',

      markers: {
        radius: 99,
      },
    },
    // yaxis: {
    //   title: false,
    // },
    grid: {
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      opacity: 1,
    },

    tooltip: {
      x: {
        show: false,
      },
      // y: {
      //   formatter: function (val) {
      //     return val;
      //   },
      // },
    },
  };

  return (
    <>
      <div className="mb-2">
        <div id="chartFour" className="-ml-5">
          <ReactApexChart
            options={options}
            series={state.series}
            type="bar"
            height={350}
          />
        </div>
      </div>
    </>
  );
};

export default ChartBar;
