import { Dispatch } from '@reduxjs/toolkit';
import { LOGOUT_SUCCESS } from 'store/auth/authTypes';
import { CATEGORY_CLEAR_STATE } from 'store/categories/categoryTypes';
import { PRODUCTS_CLEAR_STATE } from 'store/products/productTypes';
import { SHOPS_CLEAR_STATE } from 'store/shops/shopTypes';

export const resetState: any = () => (dispatch: Dispatch) => {
  dispatch({ type: LOGOUT_SUCCESS });
  dispatch({ type: SHOPS_CLEAR_STATE });
  dispatch({ type: PRODUCTS_CLEAR_STATE });
  dispatch({ type: CATEGORY_CLEAR_STATE });
};

export const handleErrors: any = (error: any) => (dispatch: Dispatch) => {
  const { status, data } = error.response;
  const { errorKey } = data;
  const sessionExpired = errorKey === 'SESSION_EXPIRED';
  if (sessionExpired) {
    dispatch(resetState());
  }
};
