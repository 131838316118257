import { latLongToCoordinates } from 'helpers/stringHelpers';
import ShopCreatorForm from './ShopCreatorForm';
import regionsByCode from 'mocks/RegionsByShortCode.json';
import axiosClient from 'config/axiosClient';
import { FormikHelpers } from 'formik';
import toast from 'react-hot-toast';

const ShopCreatorPage = () => {
  const handleSubmit = async (values: any, actions: FormikHelpers<any>) => {
    try {
      const regions = regionsByCode as any;
      const state = regions[values.country].regions[values.state];
      const data = {
        name: values.name,
        email: values.email,
        password: values.password,
        location: {
          type: 'Point',
          coordinates: latLongToCoordinates(values.gmapCoordinates),
          gmapCoordinates: values.gmapCoordinates,
          gmapLink: values.gmapLink,
          country: values.country,
          stateCode: state.shortCode,
          stateName: state.name,
        },
      };

      const toastId = toast.loading('Creando restuarant...');

      const res = await axiosClient.post('/shops/create-main', data);
      toast.dismiss(toastId);
      toast.success('Retaurant creado exitósamente');
      actions.resetForm();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <section className="bg-stone-400 overflow-hidden">
      <div className="md:flex h-screen">
        <div className="flex-shrink-0 w-1/2 hidden md:block">
          <div
            className="w-full h-full bg-cover"
            style={{
              backgroundImage:
                'url("https://storage.hewox.com/wp-content/uploads/2024/03/green-haus-lecheria-pizza.jpg")',
            }}
          />
        </div>
        <div className="flex-grow overflow-y-auto bg-primary-100 h-full">
          <section className="w-full md:max-w-md mx-auto px-3">
            <h3 className="mb-6 text-4xl mt-12 font-normal">
              Comienza a crear el menú de tu Restaurant
            </h3>
            <section className="mb-4 bg-primary-100">
              <ShopCreatorForm onSubmit={handleSubmit as any} />
            </section>
          </section>
        </div>
      </div>
    </section>
  );
};

export default ShopCreatorPage;
