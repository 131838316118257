import * as Yup from 'yup';

Yup.addMethod(Yup.string, 'creditCardType', function (errorMessage) {
  return this.test('test-card-type', errorMessage, function (value) {
    const { path, createError } = this;

    return (
      (value?.length && value?.length > 0) ||
      createError({ path, message: errorMessage })
    );
  });
});

const productYupSchema = Yup.object().shape({
  name: Yup.string().required('El nombre es requerido'),
  category: Yup.string().required('La categoría es requerida'),
  price: Yup.number().required('El precio es requerido'),
  description: Yup.string(),
  shop: Yup.string().required('La sucursal es requerida'),
});

export default productYupSchema;
