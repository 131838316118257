interface AvatarProps {
  src: string;
  className?: string;
}

const Avatar = ({
  src = 'https://storage.hewox.com/wp-content/uploads/2024/04/placeholder-2.webp',
  className,
}: AvatarProps) => {
  const classSize = className ?? 'w-6 h-6';
  return (
    <picture
      className={`${classSize} aspect-square flex-shrink-0 relative rounded-full grid place-content-center overflow-hidden`}
    >
      <img src={src} className="w-full h-full object-cover absolute inset-0" />
    </picture>
  );
};

export default Avatar;
