import { useEffect } from 'react';
import appContext from './appContext';
import { checkSession } from 'store/auth/authActions';
import { useDispatch, useSelector } from 'react-redux';
import { getCategories } from 'store/categories/categoryActions';
import { getProductsByshop } from 'store/products/productActions';
import { RootState } from 'store';
import { getShops } from 'store/shops/shopActions';
// import { setInitialDocs } from 'store/settings/settingActions';

const AppState = ({ children }: any) => {
  const dispatch = useDispatch();
  const { isAuth, currentShop, user } = useSelector(
    (state: RootState) => state.auth,
  );
  useEffect(() => {
    // dispatch(checkSession());
    if (isAuth) {
      dispatch(getShops());
    }
  }, [isAuth]);

  const getData = async () => {
    await dispatch(getProductsByshop(currentShop));
    await dispatch(getCategories(currentShop));
  };

  useEffect(() => {
    // dispatch(checkSession());
    if (currentShop) {
      getData();
    }
  }, [currentShop]);

  return <appContext.Provider value={null}>{children}</appContext.Provider>;
};

export default AppState;
