import CustomField from 'components/CustomField/CustomField';
import { Form, Formik, FormikValues } from 'formik';
import { shopCreatorValidation } from './shopCreatorValidation';
import CountrySelector from 'components/Form/CountrySelector';
import { getQueryParams } from 'helpers/stringHelpers';
import { useLocation } from 'react-router-dom';

interface ShopCreatorFormProps {
  onSubmit: (data: any) => void;
}

const ShopCreatorForm = ({ onSubmit }: ShopCreatorFormProps) => {
  const links = [
    'http://localhost:3000/creator?country=VE&region=B',
    'http://localhost:3000/creator?country=AR&region=B',
    'http://localhost:3000/creator?country=VE&region=A',
  ];
  const location = useLocation();
  const params = getQueryParams(location);
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        name: '',
        email: '',
        password: '',
        country: params.country ?? 'AF',
        state: params.region ?? '',
        gmapLink: '',
        gmapCoordinates: '',
      }}
      validationSchema={shopCreatorValidation}
    >
      {(props: FormikValues) => {
        const { values, errors, touched, handleChange } = props;
        return (
          <Form className="w-full">
            <section className="flex flex-col gap-3">
              <CountrySelector name="country" {...props} />
              <CustomField
                type="text"
                name={'gmapLink'}
                placeholder="Enlace Google Maps"
                value={values.gmapLink}
                onChange={handleChange}
                error={errors.gmapLink}
                touched={touched.gmapLink}
              />
              <CustomField
                type="text"
                name={'gmapCoordinates'}
                placeholder="Latitude,Longitude"
                value={values.gmapCoordinates}
                onChange={handleChange}
                error={errors.gmapCoordinates}
                touched={touched.gmapCoordinates}
              />

              <CustomField
                type="text"
                name={'name'}
                label="Nombre de tu restaurant"
                value={values.name}
                onChange={handleChange}
                required
                placeholder="Trattoria"
                error={errors.name}
                touched={touched.name}
              />

              <CustomField
                type="email"
                name={'email'}
                label="Correo electrónico"
                value={values.email}
                onChange={handleChange}
                required
                placeholder="Ej. mirestaurant@hewox.com"
                error={errors.email}
                touched={touched.email}
              />
              <CustomField
                type="password"
                name={'password'}
                label="Contraseña"
                value={values.password}
                onChange={handleChange}
                required
                placeholder="Contraseña"
                error={errors.password}
                touched={touched.password}
              />
              <button
                type="submit"
                className="border-2 p-2 rounded-2xl mt-3 w-full font-semibold bg-yellow-500 text-white hover:opacity-90 mb-6 transition duration-300 ease-in-out"
              >
                Crear
              </button>
            </section>
          </Form>
        );
      }}
    </Formik>
  );
};
export default ShopCreatorForm;
