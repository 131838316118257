import { useState } from 'react';
import NavLinks from './NavLinks';
import LinkLogo from 'components/Logo/LinkLogo';
import { handleGetMenew } from 'helpers/whatsappHandlers';
import BurguerButton from 'components/BurguerButton/BurguerButton';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <header className="sticky top-0 z-30 h-[70px] ">
        <nav className="h-full relative z-10">
          <section
            className={`h-full mx-auto bg-yellow-50 bg-opacity-65 shadow-lg ${
              open ? 'shadow-none md:shadow-lg' : ''
            }`}
          >
            <section className="max-w-7xl px-6 mx-auto h-full flex justify-between items-center">
              <div>
                <section className="w-24 cursor-pointer">
                  <LinkLogo />
                </section>
              </div>
              <div className="hidden md:static md:min-h-fit md:w-auto md:flex  w-full items-center px-5">
                <NavLinks />
              </div>
              <div className="flex items-center gap-6">
                <button
                  className="hidden md:block bg-transparent border-yellow-500 text-yellow-500 border-2 px-5 py-2 rounded-full"
                  onClick={handleGetMenew}
                >
                  Obtener
                </button>
                <Link
                  to={'/dashboard'}
                  className="md:block bg-yellow-500 hover:bg-yellow-500 text-white px-5 py-2 rounded-full"
                >
                  Mi cuenta
                </Link>
                <section className="hidden">
                  <BurguerButton open={open} setOpen={setOpen} />
                </section>
              </div>
            </section>
          </section>
        </nav>
        <div
          className={`duration-500 absolute  left-0 w-full px-4 py-3 bg-yellow-500 shadow-lg md:hidden ${
            open ? 'top-[70px]' : 'top-[-500%] '
          }`}
        >
          <NavLinks />
        </div>
      </header>
    </>
  );
};

export default Navbar;
