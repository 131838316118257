import { SelectOption } from 'types/globalTypes';

export const arrayMongoDocsToObject = (array: any) => {
  const object: any = {};
  array?.forEach?.((doc: any) => {
    object[doc._id] = doc;
  });
  return object;
};

export const getDocsById = (docs: any) => {
  const docsById: any = {};
  docs?.forEach?.((doc: any) => {
    docsById[doc._id] = doc;
  });
  return docsById;
};

export const getDocsByProperty = (docs: any, property: string) => {
  const docsByProperty: any = {};
  docs?.forEach?.((doc: any) => {
    if (!docsByProperty[doc[property]]) {
      docsByProperty[doc[property]] = {
        docs: [],
      };
    }
    docsByProperty[doc[property]].docs.push(doc);
  });

  return docsByProperty;
};

export const arrayToObject = (docs: any, property: string) => {
  const docsByProperty: any = {};
  docs?.forEach?.((doc: any) => {
    if (!docsByProperty[doc[property]]) {
      docsByProperty[doc[property]] = {
        ...doc,
      };
    }
  });

  return docsByProperty;
};

export const getSelectOptions = (array: any) => {
  return array?.map?.((item: any) => ({
    value: item?._id,
    label: item?.name,
  })) as SelectOption[];
};

export const createFormData = (data: any) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });
  return formData;
};

export const createFormDataStringify = (data: any) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, JSON.stringify(data[key]));
  });
  return formData;
};

export const getThumbnail = (data: any) => {
  return data?.sizes?.thumbnail?.source_url;
};

export const getSourceMedia = (media: any, source: string) => {
  return (
    media?.sizes?.[source]?.source_url ??
    'https://storage.hewox.com/wp-content/uploads/2024/04/placeholder-2.webp'
  );
};
