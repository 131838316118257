import * as types from './shopTypes';
import { Dispatch } from '@reduxjs/toolkit';
import tokenHeader from 'config/tokenHeader';
import axiosClient from 'config/axiosClient';
import { SET_CURRENT_SHOP } from 'store/auth/authTypes';
import toast from 'react-hot-toast';

export const getShops: any = () => async (dispatch: Dispatch) => {
  dispatch({ type: types.SHOPS_FETCH_START });
  try {
    await tokenHeader();
    const res = await axiosClient.get('/shops');

    dispatch({
      type: types.SHOPS_FETCH_SUCCESS,
      payload: res.data.docs,
    });
  } catch (error) {
    dispatch({
      type: types.SHOPS_FETCH_FAIL,
    });
  }
};

export const createShop: any = (shop: any) => async (dispatch: Dispatch) => {
  dispatch({ type: types.SHOPS_FETCH_START });
  try {
    await tokenHeader();
    const res = await axiosClient.post('/shops', shop);

    dispatch({
      type: types.SHOP_CREATE_SUCCESS,
      payload: res.data.doc,
    });

    dispatch({
      type: SET_CURRENT_SHOP,
      payload: res.data.doc._id,
    });
  } catch (error) {
    dispatch({
      type: types.SHOPS_FETCH_FAIL,
    });
  }
};

export const deleteShop: any =
  (shop: any, mainShop: any) => async (dispatch: Dispatch) => {
    dispatch({ type: types.SHOPS_FETCH_START });
    try {
      await tokenHeader();
      const res = await axiosClient.delete(`/shops/${shop}`);

      dispatch({
        type: SET_CURRENT_SHOP,
        payload: mainShop,
      });

      dispatch({
        type: types.SHOP_DELETE_SUCCESS,
        payload: shop,
      });
    } catch (error) {
      dispatch({
        type: types.SHOPS_FETCH_FAIL,
      });
    }
  };

export const updateShop: any = (shop: any) => async (dispatch: Dispatch) => {
  dispatch({ type: types.SHOPS_FETCH_START });
  try {
    await tokenHeader();
    const res = await axiosClient.put(`/shops/${shop._id}`, shop);
    console.log(res.data);
    toast.success('Actualización realizada con éxito');
    dispatch({
      type: types.SHOP_UPDATE_SUCCESS,
      payload: res.data.doc,
    });
  } catch (error) {
    dispatch({
      type: types.SHOPS_FETCH_FAIL,
    });
  }
};
