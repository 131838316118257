import { Icon } from '@iconify/react';
import ConfirmAction from 'components/ConfirmAction/ConfirmAction';
import { appIcons } from 'config/appIcons';
import axiosClient from 'config/axiosClient';
import tokenHeader from 'config/tokenHeader';
import { createFormData } from 'helpers/objectHelpers';
import useCurrentShop from 'hooks/useCurrentShop';
import { useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useMediaStore } from 'store/useMediaStore';

const MediaModal = ({ open, setOpen, onChange }: any) => {
  const [fileUrl, setFileUrl] = useState('');
  const [uploading, setUploading] = useState(false);
  const media = useMediaStore((state: any) => state.media);
  const addMedia = useMediaStore((state: any) => state.addMedia);
  const removeMedia = useMediaStore((state: any) => state.removeMedia);
  const [selectedMedium, setSelectedMedium]: any = useState(null);
  const shop = useCurrentShop();
  const ref: any = useRef(null);
  const openFileInput = () => {
    ref.current.click();
  };

  const onChangeFile = async (e: any) => {
    const files = e.target.files;

    if (files.length > 0) {
      try {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];

          setFileUrl(URL.createObjectURL(file));

          const data = { name: file.name, description: '', image: file };
          const formData = createFormData(data);

          await tokenHeader();
          setUploading(true);

          const res = await axiosClient.post(
            `/media/shop/${shop._id}`,
            formData,
          );

          addMedia(res.data);

          setSelectedMedium(res.data);
        }

        // Finalmente, después de procesar todos los archivos
        setUploading(false);
      } catch (error) {
        setUploading(false);
        console.error('Error al subir archivos:', error);
      }
    }
  };

  const handleSelect = () => {
    if (uploading) return;
    onChange?.(selectedMedium);
    setOpen(false);
  };

  const handleDeleteMedia = async (media: any) => {
    try {
      await tokenHeader();
      const toastId = toast.loading('Eliminando imagen');
      removeMedia(media._id);
      const res = await axiosClient.delete(
        `/media/${media._id}/shop/${shop._id}`,
      );
      toast.dismiss(toastId);
      toast.success('Imagen eliminada exitósamente');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {open && (
        <section className="bg-black bg-opacity-70 fixed z-30 inset-0 p-3">
          <section className="bg-white p-3 rounded-lg h-[95vh] pb-16 relative flex flex-col max-w-3xl mx-auto">
            <section className="flex items-center justify-between gap-2 text-zinc-600">
              <h2 className="text-xl">Medios</h2>
              <button type="button" onClick={() => setOpen(false)}>
                <Icon icon={appIcons.close} className="w-8 h-8" />
              </button>
            </section>

            <button
              onClick={openFileInput}
              type="button"
              className="bg-zinc-100 p-3 w-full rounded-md flex items-center justify-center gap-2 text-lg font-medium border border-zinc-300"
            >
              <Icon icon={appIcons.desktop} className="w-8 h-8 text-zinc-400" />
              Subir imagen
            </button>
            <input
              ref={ref}
              id="mediaFile"
              type="file"
              className="hidden"
              onChange={onChangeFile}
              accept=".png, .jpg, image/png, image/jpeg"
              multiple
            />

            <section className="grid grid-cols-3 sm:grid-cols-5 gap-2 w-full mt-4 overflow-y-scroll">
              {uploading && (
                <section className="p-1">
                  <section className="border border-zinc-300 rounded-md h-full aspect-square w-full bg-zinc-300 overflow-hidden relative">
                    <img
                      className="object-cover h-full w-full opacity-20"
                      src={fileUrl}
                    />

                    <section className="absolute w-full bg-zinc-300 inset-0 animate-pulse opacity-90 "></section>
                    <section className="absolute text-xs sm:text-base inset-0 w-full grid place-content-center text-center font-medium text-zinc-700">
                      Subiendo...
                    </section>
                  </section>
                </section>
              )}
              {media?.map?.((medium: any) => (
                <section key={medium._id} className="aspect-square relative">
                  <button
                    type={'button'}
                    className={`p-1 rounded-md w-full h-full ${
                      selectedMedium?._id === medium._id
                        ? 'bg-yellow-500'
                        : 'hover:bg-zinc-200'
                    }`}
                    onClick={() => setSelectedMedium(medium)}
                  >
                    <img
                      className="w-full h-full object-cover border border-zinc-300 rounded-md"
                      src={medium?.sizes?.thumbnail?.source_url}
                    />
                  </button>
                  <ConfirmAction
                    onConfirm={() => handleDeleteMedia(medium)}
                    className="absolute right-0 bg-white p-1 rounded-full hover:bg-yellow-500 transition-all text-black hover:text-white"
                  >
                    <Icon icon="gravity-ui:trash-bin" className="w-5 h-5" />
                  </ConfirmAction>
                </section>
              ))}
            </section>
            <section className="absolute bottom-0 w-full left-0 p-2">
              {selectedMedium || uploading ? (
                <button
                  type="button"
                  onClick={handleSelect}
                  className={` p-3 text-white rounded-lg w-full font-bold ${
                    uploading ? 'bg-zinc-300' : 'bg-yellow-500'
                  }`}
                >
                  {uploading ? 'Subiendo.. espera...' : 'Seleccionar'}
                </button>
              ) : (
                <p className="w-full text-center p-2 font-medium">
                  Elige una imagen o sube una nueva
                </p>
              )}
            </section>
          </section>
        </section>
      )}
    </>
  );
};

export default MediaModal;
