const NavLinks = () => {
  return (
    <ul className="flex flex-col gap-2 md:flex-row md:items-center md:gap-[4vw] w-full">
      {/* <li>
        <a className="text-yellow-500 hover:text-yellow-500" href={'Tu mama'}>
          Tu mamá
        </a>
      </li> */}
    </ul>
  );
};

export default NavLinks;
