/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import FieldWrapper from 'components/CustomField/FieldWrapper';
import CheckboxFive from './CheckboxFive';

interface CustomRadioGroupProps {
  options: string[];
  type: string;
  name: string;
  value: string;
  placeholder?: string;
  label?: string;
  as?: string;
  error: string;
  touched: boolean;
  required?: boolean;
  onChange: (name: any, value: any) => void;
}

const CustomRadioGroup = (props: CustomRadioGroupProps) => {
  const {
    options,
    type,
    name,
    placeholder,
    label,
    as,
    value,
    error,
    touched,
    required = false,
    onChange,
  } = props;
  const handleChange = (value: string) => {
    onChange(name, value);
  };
  return (
    <FieldWrapper label={label} error={error} required={required}>
      <section className="flex gap-2 mt-2">
        {options.map((option: string) => (
          <CheckboxFive
            key={option}
            value={option}
            onChange={handleChange}
            isChecked={option === value}
          />
        ))}
      </section>
    </FieldWrapper>
  );
};

export default CustomRadioGroup;
