import { Icon } from '@iconify/react';
import Modal from 'components/Modal/Modal';
import { appIcons } from 'config/appIcons';
import fastfoodImg from 'assets/images/fastfood.svg';

interface RewardScannedProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  reward: any;
  pointProfile?: any;
}

const RewardScanned = ({
  open,
  setOpen,
  reward,
  pointProfile,
}: RewardScannedProps) => {
  console.log(pointProfile);
  return (
    <Modal open={open} setOpen={setOpen}>
      <section className="grid place-content-center">
        <section className="bg-white px-3 py-3 rounded-md grid place-items-center min-w-[280px]">
          {!reward?.scanned && (
            <Icon icon={appIcons.check} className="w-20 h-20 text-teal-500" />
          )}
          <img
            className="w-32 h-32 border-2 rounded-lg border-zinc-300 p-1"
            src={fastfoodImg}
          />
          <h1 className="text-xl text-zinc-500 mt-2">{reward?.name}</h1>
          {pointProfile?.user?.profile?.instagram && (
            <img
              src={pointProfile?.user?.profile?.instagram?.pictureUrl}
              className="w-8 h-8 rounded-full object-cover mt-2"
            />
          )}
          {pointProfile?.user?.profile?.instagram && (
            <h2 className="font-bold">
              {pointProfile?.user?.profile?.instagram?.name}
            </h2>
          )}
          {reward?.scanned && (
            <section className="text-orange-400 bg-orange-50 p-2 rounded-full uppercase font-bold text-center mt-2">
              <span>Este premio ya fue utilizado</span>
            </section>
          )}

          {!reward?.scanned && (
            <section className="text-green-600 bg-green-50 p-2 rounded-full uppercase font-bold text-center mt-2">
              <span>Premio válido</span>
            </section>
          )}
        </section>
      </section>
    </Modal>
  );
};

export default RewardScanned;
