import { Location } from 'react-router-dom';

export const textToSlug = (text: string) => {
  return text
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-');
};

export function getLatLong(link: string) {
  // Extract latitude and longitude from the link
  const regex = /@(-?\d+\.\d+),(-?\d+\.\d+)/;
  const match = link.match(regex);

  if (match && match.length === 3) {
    const latitude = parseFloat(match[1]);
    const longitude = parseFloat(match[2]);
    return { latitude, longitude };
  } else {
    return null; // Return null if no latitude and longitude found
  }
}

export const getQueryParams = (location: Location) => {
  const searchParams: any = new URLSearchParams(location.search);
  const params: { [key: string]: string } = {};

  for (const [key, value] of searchParams.entries()) {
    params[key] = value;
  }

  return params;
};

export function latLongToCoordinates(coordinatesString: string) {
  const parts = coordinatesString.split(',');

  const coordinatesArray = parts.map((part) => parseFloat(part.trim()));

  return coordinatesArray;
}
