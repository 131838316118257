import Navbar from 'components/SiteNavbar/Navbar';
import LoginForm from './LoginForm';

const LoginPage = () => {
  return (
    <section className="bg-zinc-300 h-screen">
      <Navbar />
      <div className="container mx-auto">
        <div className="flex justify-center px-6 my-12">
          <div className="w-full xl:w-3/4 lg:w-11/12 flex flex-col sm:flex-row">
            <div className="w-full h-60 sm:h-auto lg:w-1/2 bg-cover rounded-l-lg overflow-hidden">
              <img
                src="https://storage.hewox.com/wp-content/uploads/2024/03/trattoria-2.jpg"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="w-full lg:w-1/2 bg-primary-100 p-5 rounded-lg lg:rounded-l-none">
              <h3 className="pt-4 text-2xl text-center">Welcome Back!</h3>
              <section className="px-2 md:px-8 pt-6 pb-8 mb-4 rounded">
                <LoginForm />
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginPage;
