import { getDocsById } from 'helpers/objectHelpers';
import * as types from './categoryTypes';

const initialState = {
  docs: [] as any,
  docsById: {},
  docsByShop: {},
  loading: false,
  error: null,
};

const categoryReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.CATEGORY_FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.CATEGORY_FETCH_SUCCESS:
      return {
        ...state,
        docs: action.payload.docs,
        docsById: { ...state.docsById, ...getDocsById(action.payload.docs) },
        loading: false,
      };
    case types.CATEGORY_FETCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.CATEGORY_CREATE_SUCCESS:
      return {
        ...state,
        docs: [action.payload, ...state.docs],
        docsById: { ...state.docsById, [action.payload._id]: action.payload },
        loading: false,
      };
    case types.CATEGORY_UPDATE_SUCCESS:
      return {
        ...state,
        docs: state.docs.map((category: any) =>
          category._id === action.payload._id ? action.payload : category,
        ),
        docsById: { ...state.docsById, [action.payload._id]: action.payload },
        loading: false,
      };
    case types.CATEGORY_DELETE_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const newDocs = state.docs.filter(
        (category: any) => category._id !== action.payload,
      );
      return {
        ...state,
        docs: newDocs,
        docsById: getDocsById(newDocs),
        loading: false,
      };
    case types.CATEGORY_CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};

export default categoryReducer;
