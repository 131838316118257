import * as types from './shopTypes';

const initialState = {
  docs: [] as any,
  loading: false,
  error: null,
};

const shopReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.SHOPS_FETCH_START:
      return {
        ...state,
        loading: true,
      };
    case types.SHOPS_FETCH_SUCCESS:
      return {
        ...state,
        docs: action.payload,
        loading: false,
      };
    case types.SHOPS_FETCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.SHOP_CREATE_SUCCESS:
      return {
        ...state,
        docs: [action.payload, ...state.docs],
        loading: false,
      };
    case types.SHOP_UPDATE_SUCCESS:
      return {
        ...state,
        docs: state.docs.map((shop: any) =>
          shop._id === action.payload._id ? action.payload : shop,
        ),
        loading: false,
      };
    case types.SHOP_DELETE_SUCCESS:
      return {
        ...state,
        docs: state.docs.filter((shop: any) => shop._id !== action.payload),
        loading: false,
      };
    case types.SHOP_CREATE_CATEGORY:
      // console.log(action.payload);
      return {
        ...state,
        docs: state.docs.map((shop: any) =>
          shop._id === action.payload.shopId
            ? {
                ...shop,
                catSort: [action.payload.categoryId, ...shop.catSort],
              }
            : shop,
        ),
        loading: false,
      };
    case types.SHOP_DELETE_CATEGORY:
      return {
        ...state,
        docs: state.docs.map((shop: any) =>
          shop._id === action.payload.shopId
            ? {
                ...shop,
                catSort: shop.catSort.filter(
                  (cat: string) => cat !== action.payload.categoryId,
                ),
              }
            : shop,
        ),
        loading: false,
      };

    case types.SHOP_UPDATE_CATEGORY_SORT:
      return {
        ...state,
        docs: state.docs.map((shop: any) =>
          shop._id === action.payload.shopId
            ? {
                ...shop,
                catSort: action.payload.catSort,
              }
            : shop,
        ),
        loading: false,
      };
    case types.SHOPS_CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};

export default shopReducer;
