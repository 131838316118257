import { useEffect } from 'react';
import { SelectOption } from 'types/globalTypes';
import CustomSelect from './CustomSelect';

interface CustomSelectCategoryProps {
  options: SelectOption[];
  name: string;
  value: string;
  placeholder?: string;
  label?: string;
  error: string;
  touched: boolean;
  required?: boolean;
  productCategory?: string;
  setFieldValue?: (a: any, b: any) => void;
  onChange: (name: string, value: any) => void;
}

const CustomSelectCategory = (props: CustomSelectCategoryProps) => {
  const { productCategory = '', setFieldValue, name } = props;

  useEffect(() => {
    if (productCategory) {
      setFieldValue?.(name, productCategory);
    }
  }, [productCategory]);

  return (
    <>
      <CustomSelect {...props} />
    </>
  );
};

export default CustomSelectCategory;
