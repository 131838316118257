// import HomePage from 'pages/Home/HomePage';
import CategoriesPage from 'pages/Categories/CategoriesPage';
import DashboardPage from 'pages/Dashboard/DashboardPage';
import OrdersPage from 'pages/Order/OrdersPage';
import ProductsPage from 'pages/Products/ProductsPage';
import NewShopPage from 'pages/Shops/NewShopPage';
import ShopSettingsPage from 'pages/Shops/ShopSettingsPage';

interface Route {
  path: string;
  label: string;
  element: JSX.Element;
  showInMenu: boolean;
}

const routesApp = [
  {
    path: '/dashboard',
    label: 'Tablero',
    element: <DashboardPage />,
    showInMenu: true,
  },
  {
    path: '/products',
    label: 'Productos',
    element: <ProductsPage />,
    showInMenu: true,
  },
  {
    path: '/categories',
    label: 'Categorías',
    element: <CategoriesPage />,
    showInMenu: true,
  },
  {
    path: '/new-shop',
    label: 'New Shop',
    element: <NewShopPage />,
  },
  {
    path: '/shop-settings',
    label: 'Settings',
    element: <ShopSettingsPage />,
  },
  {
    path: '/orders',
    label: 'Orders',
    element: <OrdersPage />,
  },
];

export default routesApp as Route[];
