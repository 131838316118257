import axiosClient from 'config/axiosClient';
import tokenHeader from 'config/tokenHeader';
import { useEffect, useState } from 'react';
import { setTagsStore, useTagsStore } from 'store/useTagsStore';

const useGetTags = () => {
  const tags = useTagsStore((state) => state.tags);
  const [isLoading, setLoading] = useState(false);
  const getTags = async () => {
    try {
      setLoading(true);
      await tokenHeader();
      const res = await axiosClient.get('/tags');
      setLoading(false);
      setTagsStore({ tags: res.data.docs });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTags();
  }, []);

  return { tags, isLoading } as { tags: []; isLoading: boolean };
};

export default useGetTags;
