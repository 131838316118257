import { useInterval } from 'hooks/useInterval';
import { useState } from 'react';

interface Image {
  src: string;
}

interface Props {
  images: Image[];
  autoplay?: boolean;
  autoplayInterval?: number;
  controls?: boolean;
}

const Carousel = ({
  images,
  autoplay = true,
  autoplayInterval = 3500,
  controls = false,
}: Props) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useInterval(
    () => {
      setCurrentIndex((currentIndex + 1) % images.length);
    },
    autoplay ? autoplayInterval : null,
  );

  const handlePrev = () => {
    setCurrentIndex((currentIndex + images.length - 1) % images.length);
  };

  const handleNext = () => {
    setCurrentIndex((currentIndex + 1) % images.length);
  };

  const handleDotClick = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <div className="relative w-full h-full">
      {controls && (
        <>
          <button className="absolute top-0 left-0 p-2" onClick={handlePrev}>
            Prev
          </button>
          <button className="absolute top-0 right-0 p-2" onClick={handleNext}>
            Next
          </button>
        </>
      )}
      <div className="overflow-hidden h-full">
        <div
          className="relative h-full transition-all duration-500 ease-in-out"
          style={{
            transform: `translateX(${-currentIndex * 100}%)`,
          }}
        >
          {images.map((image, index) => (
            <img
              key={index}
              className="w-full h-full object-cover"
              src={image.src}
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: `${index * 100}%`,
              }}
            />
          ))}
        </div>
      </div>
      <div className="absolute bottom-0 w-full flex justify-center p-2">
        {images.map((_, index) => (
          <button
            key={index}
            className={`h-2 w-2 rounded-full m-1 ${
              index === currentIndex ? 'bg-gray-900' : 'bg-gray-500'
            }`}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
