import { combineReducers } from 'redux';
import productReducer from './products/productReducer';
import authReducer from './auth/authReducer';
import getDocReducer from './docs/docReducers';
import { types } from './docs/docTypes';
import shopReducer from './shops/shopReducer';
import categoryReducer from './categories/categoryReducer';

const reducers = {
  auth: authReducer,
  products: productReducer,
  categories: categoryReducer,
  tags: getDocReducer(types.TAG),
  shops: shopReducer,
};

export default combineReducers(reducers);
