import { Fragment } from 'react';
import { SelectOption } from 'types/globalTypes';

interface SelectDropdownProps {
  options: SelectOption[];
  open: boolean;
  wrapClassName?: string;
  onChange: (e: any) => void;
}

const SelectDropdown = ({
  options,
  open,
  wrapClassName,
  onChange,
}: SelectDropdownProps) => {
  const baseClass =
    'absolute right-0 z-10 w-full origin-top-right rounded-b-md bg-white focus:outline-none border-2 border-t-0 border-gray-300 max-h-[300px] overflow-y-auto';
  const className = `${baseClass} ${wrapClassName} ${
    open ? 'block' : 'hidden'
  } `;
  return (
    <section
      className={className}
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabIndex={-1}
    >
      <section className="py-1" role="none">
        {/* Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" */}
        {options?.map((option: SelectOption, index) => {
          const isFirst = index === 0;
          return (
            <Fragment key={option.value}>
              {!isFirst && <hr className="border-gray-200" />}
              <button
                className="text-gray-700 block px-4 py-2 text-sm w-full text-left hover:bg-gray-50"
                role="menuitem"
                tabIndex={-1}
                id="menu-item-0"
                onClick={() => onChange(option)}
                type="button"
              >
                {option.label}
              </button>
            </Fragment>
          );
        })}
      </section>
    </section>
  );
};

export default SelectDropdown;
