import axiosClient from 'config/axiosClient';
import * as types from './productTypes';
import tokenHeader from 'config/tokenHeader';
import { ProductInfo, ProductResponse } from 'types/productTypes';
import { Dispatch } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';

const requestProducts = () => ({
  type: types.PRODUCTS_FETCH_REQUEST,
});

export const receiveProducts = (products: ProductResponse[]) => ({
  type: types.PRODUCTS_FETCH_SUCCESS,
  payload: products,
});

const invalidProducts = () => ({
  type: types.PRODUCTS_FETCH_FAIL,
});

export const getProductsByshop: any =
  (shop: string) => async (dispatch: Dispatch) => {
    dispatch(requestProducts());
    try {
      const res = await axiosClient.get(`/products?shop=${shop}`);
      const { docs } = res.data;
      dispatch({
        type: types.PRODUCTS_FETCH_SUCCESS,
        payload: { shop, docs },
      });
    } catch (error) {
      console.error(error);
      dispatch(invalidProducts());
    }
  };

export const createProduct =
  (product: ProductInfo) => async (dispatch: Dispatch) => {
    dispatch(requestProducts());
    try {
      await tokenHeader();
      const res = await axiosClient.post('/products', product);

      dispatch({
        type: types.PRODUCT_CREATE_SUCCESS,
        payload: res.data.doc,
      });

      toast.success('Producto creado exitósamente');
    } catch (error) {
      dispatch(invalidProducts());
    }
  };

export const updateProduct = (product: any) => async (dispatch: Dispatch) => {
  dispatch(requestProducts());
  try {
    await tokenHeader();
    const res = await axiosClient.put(`/products/${product._id}`, product);

    dispatch({
      type: types.PRODUCT_UPDATE_SUCCESS,
      payload: res.data.doc,
    });

    toast.success('Producto Actualizado');
  } catch (error) {
    dispatch({
      type: types.PRODUCTS_FETCH_FAIL,
    });
  }
};

export const deleteProduct = (product: any) => async (dispatch: Dispatch) => {
  dispatch(requestProducts());
  try {
    await tokenHeader();
    await axiosClient.delete(`/products/${product._id}`);
    dispatch({
      type: types.PRODUCT_DELETE_SUCCESS,
      payload: product,
    });
    toast.success('Producto Eliminado');
  } catch (error) {
    dispatch({
      type: types.PRODUCTS_FETCH_FAIL,
    });
  }
};

export const importStoreProducts =
  (fromStore: string, toStore: string) => async (dispatch: Dispatch) => {
    dispatch(requestProducts());
    try {
      await tokenHeader();
      const res = await axiosClient.post('/products/import', {
        fromStore,
        toStore,
      });
      dispatch(receiveProducts(res.data.docs));
    } catch (error) {
      dispatch(invalidProducts());
    }
  };
