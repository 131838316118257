import { useState } from 'react';

type FilterFields = string[] | { field: string; subField: string }[];

const useFilterItems = (items: [], filterFields: FilterFields) => {
  const [searchText, setSearchText] = useState('');

  const searchInItemsByAllFields = () => {
    const normalizedSearchText = (text: string) =>
      text
        ?.toString()
        ?.normalize('NFD')
        ?.replace(/[\u0300-\u036f]/g, '');
    return items.filter((item) => {
      return filterFields.some((field) => {
        if (typeof field === 'string') {
          return normalizedSearchText(item[field])
            ?.toLowerCase?.()
            ?.includes(normalizedSearchText(searchText)?.toLowerCase?.());
        } else {
          return normalizedSearchText(item[field.field][field.subField])
            ?.toLowerCase?.()
            ?.includes(normalizedSearchText(searchText).toLowerCase?.());
        }
      });
    });
  };

  const filteredItems = searchText !== '' ? searchInItemsByAllFields() : items;
  return [filteredItems, searchText, setSearchText];
};

export default useFilterItems;
