import * as Yup from 'yup';

export const shopCreatorValidation = Yup.object().shape({
  name: Yup.string().required('Required'),
  country: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  gmapLink: Yup.string().required('Required'),
  gmapCoordinates: Yup.string().required('Required'),
  email: Yup.string().email('Email inválido').required('Required'),
  password: Yup.string()
    .min(8, 'Muy corta!')
    .max(16, 'Muy larga!')
    .required('Requerida'),
});

export const authCodeValidation = Yup.object().shape({
  email: Yup.string().email('Email inválido').required('Required'),
  code: Yup.string().min(6, 'Muy corto!').max(6, 'Muy largo!'),
});
