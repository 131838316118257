import { Switch } from '@headlessui/react';

interface ToggleSwitchProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label?: string;
}

const ToggleSwitch = ({ checked, onChange, label }: ToggleSwitchProps) => {
  return (
    <section className="flex items-center  gap-1 rounded-md text-[0.75rem]">
      {label && <span className="block text-xs text-gray-600">{label}</span>}
      <Switch
        checked={checked}
        onChange={onChange}
        className={`${
          checked ? 'bg-yellow-500' : 'bg-gray-200'
        } relative inline-flex h-[1.25em] w-[2em] items-center rounded-full`}
      >
        <span className="sr-only">Enable</span>
        <span
          className={`${
            checked ? 'translate-x-[1em]' : 'translate-x-[0.15em]'
          } inline-block h-[0.85em] w-[0.85em] transform rounded-full bg-primary-100 transition`}
        />
      </Switch>
    </section>
  );
};

export default ToggleSwitch;
