import { Menu, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';
import { authLogout } from 'store/auth/authActions';
import ShopSelector from './ShopSelector';
import { Icon } from '@iconify/react';
import Avatar from 'components/Avatar/Avatar';
import { Link, useNavigate } from 'react-router-dom';
import { resetState } from 'store/actions/globalActions';
import useCurrentShop from 'hooks/useCurrentShop';
import { getSourceMedia } from 'helpers/objectHelpers';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

const ProfileDropdown = () => {
  const dispatch: AppDispatch = useDispatch();
  const [openShops, setOpenShops] = useState(false);
  const shop = useCurrentShop();
  const shopImage = getSourceMedia(shop.logo, 'thumbnail');
  const navigate = useNavigate();
  const logout = () => {
    dispatch(authLogout());
    dispatch(resetState());
    navigate('/my-account');
  };

  const afterSelectShop = () => {
    setTimeout(() => {
      setOpenShops(false);
    }, 500);
  };
  return (
    <Menu as="div" className="relative z-20">
      <div>
        <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none">
          <span className="sr-only">Open user menu</span>
          <section className="bg-primary-100 h-[2.82rem] w-[2.82rem] rounded-full overflow-hidden">
            {shopImage && (
              <img
                className="w-full h-full object-cover"
                src={shopImage}
                alt=""
              />
            )}
          </section>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 min-w-[16rem] origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <section className="grid">
            {openShops ? (
              <section className="">
                <button
                  onClick={() => setOpenShops(false)}
                  className="flex text-gray-800 font-semibold text-lg px-4 whitespace-nowrap my-3 items-center gap-2 pr-28"
                >
                  <Icon icon="ep:back" className="w-6 h-6" />
                  <span>Seleccionar sucursal</span>
                </button>
                <ShopSelector onClick={afterSelectShop} />
              </section>
            ) : (
              <>
                <section className="px-2 py-3">
                  <section
                    className={
                      'block text-sm text-gray-700 w-full text-left shadow-md rounded-md'
                    }
                  >
                    <div className="px-4 py-2 flex items-center gap-2 mb-1 ">
                      <Avatar src={shopImage} />
                      <span className="font-bold whitespace-nowrap">
                        {shop.name}
                      </span>
                    </div>
                    <hr className="border-t border-gray"></hr>
                    <button
                      onClick={() => setOpenShops(true)}
                      className="whitespace-nowrap px-4 py-2 w-full text-left hover:bg-gray-100"
                    >
                      Sucursales
                    </button>
                  </section>
                </section>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to={'/shop-settings'}
                      className={classNames(
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm w-full text-left',
                      )}
                    >
                      <span className="text-gray-700">Configuración</span>
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={logout}
                      className={classNames(
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-yellow-500 w-full text-left',
                      )}
                    >
                      Cerrar sesión
                    </button>
                  )}
                </Menu.Item>
              </>
            )}
          </section>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ProfileDropdown;
