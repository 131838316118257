import { UilEdit, UilTrashAlt } from '@iconscout/react-unicons';
import ConfirmAction from 'components/ConfirmAction/ConfirmAction';

interface DocActionsProps {
  trashAction: () => void;
  editAction: () => void;
}

const DocActions = ({ trashAction, editAction }: DocActionsProps) => (
  <section className="rowgap">
    <ConfirmAction onConfirm={trashAction}>
      <UilTrashAlt className="text-gray-700" />
    </ConfirmAction>
    <button type="button" onClick={editAction}>
      <UilEdit className="text-gray-700" />
    </button>
  </section>
);
export default DocActions;
