import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

export const useTagsStore = create(
  persist(
    () => ({
      tags: {},
    }),
    {
      name: 'orders-storage',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

export const setTagsStore = (data: any) => {
  useTagsStore.setState((state) => data);
};
