import React, { useState, useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import routes from 'routing/routes';
import LoadingFullScreen from 'components/LoadingFullScreen/LoadingFullScreen';
import SignUpPage from 'pages/Signup/SignUpPage';
import HomePage from 'pages/Home/HomePage';
import LoginPage from 'pages/Login/LoginPage';
import Loader from 'components/Loader';
import DefaultLayout from 'components/Layout/DefaultLayout';
import OrderNumberPage from 'pages/Order/OrderNumberPage';
import ShopCreatorPage from 'pages/ShopCreator/ShopCreatorPage';

const RouterApp = () => {
  const [appLoaded, setAppLoaded] = useState(false);

  useEffect(() => {
    const handleLoad = () => {
      setAppLoaded(true);
    };

    document.addEventListener('load', handleLoad);
    return () => {
      document.removeEventListener('load', handleLoad);
    };
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!appLoaded) {
        setAppLoaded(true);
      }
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [appLoaded]);

  return (
    <div className="App">
      {!appLoaded && <LoadingFullScreen />}
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <Suspense fallback={<Loader />}>
                <HomePage />
              </Suspense>
            }
          />
          <Route path="/creator" element={<ShopCreatorPage />} />
          <Route path="/my-account" element={<LoginPage />} />
          <Route
            path="/order/:orderNumber"
            element={
              <Suspense fallback={<Loader />}>
                <OrderNumberPage />
              </Suspense>
            }
          />
          <Route element={<DefaultLayout />}>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={
                  <Suspense fallback={<Loader />}>{route.element}</Suspense>
                }
              />
            ))}
          </Route>
        </Routes>
      </Router>
    </div>
  );
};

export default RouterApp;
