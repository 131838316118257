import { useState } from 'react';
import ModalForm from 'components/ModalForm/ModalForm';
import DocActions from 'components/DocItem/DocActions';
import Text from 'components/Text/Text';
import MethodForm from './MethodForm';

interface MethodItemProps {
  method: any;
  index: number;
  name: string;
  values: [];
  onChange: (field: string, value: any) => void;
}

const MethodItem = ({
  method,
  index,
  name,
  values,
  onChange,
}: MethodItemProps) => {
  const [open, setOpen] = useState(false);

  const handleUpdate = async (data: any) => {
    const methods: any = values;
    methods[index] = data;
    onChange?.(name, methods);
    setOpen(false);
  };
  const handleDelete = () => {
    const methods: any = values;
    methods.splice(index, 1);
    onChange?.(name, methods);
    setOpen(false);
  };

  return (
    <section className="between flex-wrap-reverse">
      <Text type="itemTitle" className="font-medium">
        {method?.name}
      </Text>
      <section className="flex justify-end py-1">
        <DocActions
          trashAction={handleDelete}
          editAction={() => setOpen(true)}
        />
      </section>
      <ModalForm
        open={open}
        onClose={() => setOpen(false)}
        title={'Editar método de pago'}
      >
        <MethodForm method={method} onSubmit={handleUpdate} />
      </ModalForm>
    </section>
  );
};

export default MethodItem;
