import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ProductResponse } from 'types/productTypes';
import ModalForm from 'components/ModalForm/ModalForm';
import ProductForm from './ProductForm/ProductForm';
import { deleteProduct, updateProduct } from 'store/products/productActions';
import { AppDispatch } from 'store';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import DocActions from 'components/DocItem/DocActions';
import CardItem from 'components/CardItem/CardItem';
import { getThumbnail } from 'helpers/objectHelpers';

interface ProductItemProps {
  product: ProductResponse;
}

const ProductItem = ({ product }: ProductItemProps) => {
  const { name, price } = product;
  const [soldOut, setSoldOut] = useState(product.soldOut);
  const [featured, setFeatured] = useState(product.featured);
  const [published, setPublished] = useState(product.published);
  const [open, setOpen] = useState(false);
  const dispatch: AppDispatch = useDispatch();

  const handleUpdate = async (data: any) => {
    data._id = product._id;
    await dispatch(updateProduct(data));
    setOpen(false);
  };

  const handleDelete = () => {
    dispatch(deleteProduct({ _id: product._id, shop: product.shop }));
  };

  const handleSoldOut = () => {
    setSoldOut(!soldOut);
    handleUpdate({ ...product, soldOut: !soldOut });
  };

  const handleFeatured = () => {
    setFeatured(!featured);
    handleUpdate({ ...product, featured: !featured });
  };

  const handlePublished = () => {
    setPublished(!published);
    handleUpdate({ ...product, published: !published });
  };

  const thumbnail = getThumbnail(product.image);

  return (
    <CardItem>
      <section className="flex w-full gap-2">
        <section className="hidden sm:block w-12 h-12 bg-gray-100 rounded-md overflow-hidden flex-shrink-0">
          {thumbnail && (
            <img src={thumbnail} className="w-full h-full object-cover" />
          )}
        </section>
        <section className="w-full">
          <section className="flex justify-between w-full mb-1 items-center">
            <span className="font-semibold text-base">{name}</span>
            <span className="text-sm font-semibold">{price}$</span>
          </section>
          <section className="flex justify-between">
            <section className="flex flex-col gap-1">
              <ToggleSwitch
                checked={published}
                onChange={handlePublished}
                label="Publicado"
              />
              <ToggleSwitch
                checked={soldOut}
                onChange={handleSoldOut}
                label="Agotado"
              />
              {/* <ToggleSwitch
                checked={featured}
                onChange={handleFeatured}
                label="Destacado"
              /> */}
            </section>
            <section className="grid place-content-end">
              <DocActions
                trashAction={handleDelete}
                editAction={() => setOpen(true)}
              />
            </section>
          </section>
        </section>
      </section>
      <ModalForm
        open={open}
        onClose={() => setOpen(false)}
        title={'Editar producto'}
      >
        <ProductForm onSubmit={handleUpdate} product={product} />
      </ModalForm>
    </CardItem>
  );
};

export default ProductItem;
