const ChartCard = ({ className, title, children }: any) => {
  return (
    <div
      className={`${className} col-span-12 rounded-sm border border-stroke bg-white px-5 pt-5 pb-3 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5`}
    >
      {title && (
        <div className="mb-4">
          <h3 className="text-xl text-zinc-400 dark:text-white">{title}</h3>
        </div>
      )}

      {children}
    </div>
  );
};

export default ChartCard;
