import axiosClient from 'config/axiosClient';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

export const useOrders = create(
  persist(
    () => ({
      ordersByShop: {},
    }),
    {
      name: 'orders-storage',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

export const setOrders = (orders: any, shopId: any) => {
  useOrders.setState((state) => ({
    ordersByShop: { ...state.ordersByShop, [shopId]: orders },
  }));
};

export const fetchUnpaidOrders = async (shopId: string) => {
  try {
    const res = await axiosClient.get(`/orders/shop/${shopId}/unpaid`);
    setOrders(res.data.docs, shopId);
  } catch (error) {
    console.log(error);
  }
};
